import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section34.module.scss";
import { useState } from "react";

const Section34 = (props: any) => {
  const { backHandler } = props;
  const [iconSrc, setIconSrc] = useState("assets/icons/circleBlue.svg");
  return (
    <Box id="productionaudiovisuelle" className={styles.mainContainer}>
      <Box className={styles.container}>
        <div className={styles.imgContainer}>
          <img
            src={"assets/images/expertise/productionaudiovisuelle.png"}
            srcSet={"assets/images/expertise/productionaudiovisuelle.png"}
            alt="logo"
            loading="lazy"
            className={styles.img1}
          />
          <img
            src={"assets/images/expertise/studiographic/img2.png"}
            srcSet={"assets/images/expertise/studiographic/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/expertise/studiographic/img3.png"}
            srcSet={"assets/images/expertise/studiographic/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
        </div>
        <Box className={styles.textContainer}>
          <Box
            className="flex-start"
            onMouseOver={() => setIconSrc("assets/icons/circle.svg")}
            onMouseOut={() => setIconSrc("assets/icons/circleBlue.svg")}
            onClick={backHandler}
          >
            <IonIcon icon={iconSrc} className={styles.icon2} />
            <Typography className={styles.tg2}>
              Production Audiovisuelle
            </Typography>
          </Box>
          <Typography className={styles.tg3}>
            Nous aidons nos clients à adresser le bon message, aux bonnes
            personnes, de la bonne manière, grâce à la puissance de la
            vidéo.Nous accompagnons nos clients dans la conception, la création
            et la réalisation de leur contenu vidéo, leur permettant ainsi de
            rayonner sur leur marché, en multipliant les points de contact avec
            leurs clients ou en interne avec une culture d’entreprise forte.
          </Typography>
        </Box>
      </Box>
      <Box className={styles.backIconContainer}>
        <IonIcon
          icon={"assets/icons/arrowLeft.svg"}
          className={styles.backIcon}
          onClick={backHandler}
        />
        <IonIcon
          icon={"assets/icons/back.svg"}
          className={styles.backIconMobile}
          onClick={backHandler}
        />
      </Box>
    </Box>
  );
};

export default Section34;
