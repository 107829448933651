import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section1.module.scss";

const Section1 = () => {
  return (
    <Box className={styles.section1}>
      <Box className={styles.tgContainer}>
        <Typography className={styles.tg1}>We have stories</Typography>
        <Typography className={styles.tg1m}>We have</Typography>
        <Typography className={styles.tg1m}>stories</Typography>
        <Typography className={styles.tg2}>to share.</Typography>
      </Box>
      <Box className={styles.imgContainer}>
        <img
          src={"assets/images/portfolio/img1.png"}
          srcSet={"assets/images/portfolio/img1.png"}
          alt="logo"
          loading="lazy"
          className={styles.img1}
        />
      </Box>
    </Box>
  );
};

export default Section1;
