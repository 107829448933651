import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section2.module.scss";
import { IonIcon } from "@ionic/react";
import { sendEmail } from "../../../services/sendEmail";
import { useState } from "react";
import {
  checkIsValidEmail,
  validatePhoneNumber,
} from "../../../utils/functions";
import { addSubscriber } from "../../../services/subscriber";
import { email } from "../../../utils/constants/global";

const Section2 = () => {
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [fromError, setFromError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [textError, setTextError] = useState("");
  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [checked, setChecked] = useState(false);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const validateForm = () => {
    let valid = true;

    if (!from) {
      setFromError("Ce champ est obligatoire!");
      valid = false;
    } else if (!checkIsValidEmail(from)) {
      setFromError("Email n'est pas valide!");
    } else {
      setFromError("");
    }
    if (!subject) {
      setSubjectError("Ce champ est obligatoire!");
      valid = false;
    } else {
      setSubjectError("");
    }
    if (!text) {
      setTextError("Ce champ est obligatoire!");
      valid = false;
    } else {
      setTextError("");
    }
    if (!name) {
      setNameError("Ce champ est obligatoire!");
      valid = false;
    } else {
      setNameError("");
    }
    if (mobile && !validatePhoneNumber(mobile)) {
      setMobileError("Téléphone n'est pas valide!");
      valid = false;
    } else {
      setMobileError("");
    }
    return valid;
  };

  const sendEmailHandler = () => {
    if (!validateForm()) {
      return;
    }
    const mailOptions = {
      to: email,
      from: from,
      subject: subject,
      text: text,
      name: name,
      mobile: mobile,
    };
    sendEmail(mailOptions);
    setFrom("");
    setName("");
    setSubject("");
    setText("");
    setMobile("");
    setChecked(false);
    setFromError("");
    setNameError("");
    setSubjectError("");
    setTextError("");
    setMobileError("");
    if (checked) {
      addSubscriber({ email: from });
    }
  };
  const changeToHandler = (e: any) => {
    setFrom(e.target.value);
  };
  const changeSubjectHandler = (e: any) => {
    setSubject(e.target.value);
  };
  const changeTextHandler = (e: any) => {
    setText(e.target.value);
  };
  const changeNameHandler = (e: any) => {
    setName(e.target.value);
  };
  const changeMobileHandler = (e: any) => {
    setMobile(e.target.value);
  };
  return (
    <Box className={styles.section2}>
      <Box className="flex-center" mb="40px">
        <Typography className={styles.tg1}>Parlons Nous</Typography>
        <img
          src={"assets/images/contact/img2.png"}
          srcSet={"assets/images/contact/img2.png"}
          alt="logo"
          loading="lazy"
          className={styles.img1}
        />
      </Box>
      <Box className={styles.formContainer}>
        <input
          type="text"
          className={styles.input}
          placeholder="Nom"
          onChange={changeNameHandler}
          value={name}
        />
        {nameError && <p className={styles.errorText}>{nameError}</p>}
        <input
          type="email"
          className={styles.input}
          placeholder="E-mail"
          onChange={changeToHandler}
          value={from}
        />
        {fromError && <p className={styles.errorText}>{fromError}</p>}
        <input
          type="text"
          className={styles.input}
          placeholder="Téléphone"
          onChange={changeMobileHandler}
          value={mobile}
        />
        {mobileError && <p className={styles.errorText}>{mobileError}</p>}

        <input
          type="text"
          className={styles.input}
          placeholder="Objet"
          onChange={changeSubjectHandler}
          value={subject}
        />
        {subjectError && <p className={styles.errorText}>{subjectError}</p>}
        <textarea
          className={styles.textarea}
          placeholder="Message"
          rows={8}
          onChange={changeTextHandler}
          value={text}
        ></textarea>
        {textError && <p className={styles.errorText}>{textError}</p>}
      </Box>
      <Box className={styles.checkboxContainer}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                classes={{ root: styles.checkbox }}
              />
            }
            label="Je souhaite m'inscrire à la newsletter"
            classes={{ label: styles.label }}
          />
        </FormGroup>
      </Box>
      <Button
        className={styles.btn}
        variant="contained"
        color="primary"
        onClick={sendEmailHandler}
      >
        Envoyer
      </Button>
      <Box className={styles.contactsContainer} width="100%" mt="50px">
        <Box className={styles.imgContainer}>
          <img
            src={"assets/images/contact/img4.png"}
            srcSet={"assets/images/contact/img4.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
          <img
            src={"assets/images/contact/img3.png"}
            srcSet={"assets/images/contact/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/contact/img5.png"}
            srcSet={"assets/images/contact/img5.png"}
            alt="logo"
            loading="lazy"
            className={styles.img5}
          />
          <img
            src={"assets/images/contact/img6.png"}
            srcSet={"assets/images/contact/img6.png"}
            alt="logo"
            loading="lazy"
            className={styles.img6}
          />
        </Box>
        <Box className={styles.contacts}>
          <Box justifyContent="flex-start" className={styles.contactContainer}>
            <IonIcon
              icon={"assets/icons/call.svg"}
              style={{ marginTop: "10px" }}
              className={styles.icon1}
            />
            <Box className="flex-c-cc" ml="10px">
              <Typography className={styles.tgContact}>
                +216 71 775 079
              </Typography>
              <Typography className={styles.tgContact}>
                +216 27 714 714
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={styles.contactContainer}
          >
            <IonIcon
              icon={"assets/icons/message.svg"}
              className={styles.icon1}
              style={{ marginTop: "8px" }}
            />
            <Typography
              className={styles.tgContact + " " + styles.underline}
              ml="10px"
            >
              emotioncolors@ec.tn
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={styles.contactContainer}
          >
            <IonIcon
              icon={"assets/icons/location.svg"}
              className={styles.icon2}
              style={{ marginTop: "5px" }}
            />
            <Typography
              className={styles.tgContact + " " + styles.underline}
              ml="10px"
            >
              Rue du Pakistan 1053 <br />
              La Marsa, Tunisie
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Section2;
