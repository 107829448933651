import Box from "@mui/material/Box";
import styles from "./Slider.module.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  const images = [
    "assets/images/homePage/slide1.png",
    "assets/images/homePage/slide2.png",
    "assets/images/homePage/slide3.png",
    "assets/images/homePage/slide4.png",
  ];

  return (
    <Box className={styles.slider}>
      <Box id="hpslider" className={styles.sliderContainer}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          showIndicators={false}
          showArrows={false}
          autoPlay
        >
          {images.map((image: string) => (
            <div>
              <img src={image} alt="Image 1" />
            </div>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Slider;
