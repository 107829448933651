import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import styles from "./MainCard.module.scss";
import config from "../../config/config";

const MainCard = (props: any) => {
  const { data } = props;
  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography variant="h5" component="h2" className={styles.title}>
          {data.title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={styles.description}
        >
          {data.description}
        </Typography>
        <Button className={styles.button} onClick={props.onClick}>
          Lire la suite
        </Button>
      </CardContent>
      <CardMedia
        className={styles.cardMedia}
        component="img"
        image={config.apiUrl + "/articles/readFile/" + data.image}
        alt="Card Image"
      />
    </Card>
  );
};

export default MainCard;
