import Typography from "@mui/material/Typography";
import Layout from "../components/layout";
import { useState, useEffect } from "react";
import { Card, Box, CardActions, Grid, CardMedia } from "@mui/material";
import { Pagination, PaginationItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Toast } from "../components/toast/Toast";
import { deleteVideo, getAllVideos } from "../../../services/admin/video";
import AddVideo from "./AddVideo";
import config from "../../../config/config";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const Videos = () => {
  const perPage = 6;
  const [videos, setVideos] = useState();
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    setLoading(true);
    getAllVideos(page, perPage)
      .then((data) => {
        setLoading(false);
        setVideos(data.rows);
        setNumberOfPages(Math.ceil(data.count / perPage));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, perPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleDelete = (id) => {
    deleteVideo(id)
      .then(() => {
        handleOpenToast("La vidéo a été supprimée avec succès!", "success");
        getAllVideos(page, perPage)
          .then((data) => {
            setVideos(data.rows);
            setNumberOfPages(Math.ceil(data.count / perPage));
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        handleOpenToast("Il y a un problème!", "error");
        console.error("Error deleting vidéo:", error);
      });
  };

  const getVideosHandler = () => {
    setLoading(true);
    getAllVideos(page, perPage)
      .then((data) => {
        setLoading(false);
        setVideos(data.rows);
        setNumberOfPages(Math.ceil(data.count / perPage));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      {!loading && videos && videos.length === 0 && (
        <Card style={{ padding: "30px" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
            style={{ marginBottom: "20px" }}
          >
            Aucune vidéo ! Veuillez ajouter votre première vidéo
          </Typography>
          <AddVideo getVideos={getVideosHandler} />
        </Card>
      )}
      {!loading && videos && videos.length > 0 && (
        <>
          <Typography
            variant="h4"
            noWrap
            sx={{ flexGrow: 1 }}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Liste des vidéos
          </Typography>
          <Box>
            <AddVideo getVideos={getVideosHandler} />
          </Box>
          <Grid container spacing={2} mt="5px">
            {videos.map((video, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      component="video"
                      controls
                      width="320"
                      height="180"
                      src={config.apiUrl + "/videos/readFile/" + video.title}
                    />
                    <CardActions style={{ padding: "unset" }}>
                      <IconButton
                        className="deleteBtn"
                        aria-label="Delete"
                        onClick={() => handleDelete(video.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
          {numberOfPages > 1 && (
            <Box display="flex" justifyContent="center" mt="40px">
              <Pagination
                count={numberOfPages}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem component="button" {...item} />
                )}
              />
            </Box>
          )}
        </>
      )}
      {loading && <CircularProgress style={{ marginTop: "30px" }} />}
    </Layout>
  );
};

export default Videos;
