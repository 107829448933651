import { useRef, useEffect } from "react";
import styles from "./Video.module.scss";
import config from "../../config/config";

function Video(props) {
  const { title } = props;
  const videoRef = useRef(null);
  useEffect(() => {
    captureScreenshot();
  }, [videoRef]);

  const captureScreenshot = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    }
  };

  return (
    <>
      <video ref={videoRef} controls className={styles.video}>
        <source
          src={config.apiUrl + "/videos/readFile/" + title + "#t=0.1"}
          type="video/mp4"
        />
      </video>
    </>
  );
}

export default Video;
