import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section33.module.scss";
import { useState } from "react";

const Section33 = (props: any) => {
  const { backHandler } = props;
  const [iconSrc, setIconSrc] = useState("assets/icons/circleBlue.svg");
  return (
    <Box id="video2D3D" className={styles.mainContainer}>
      <Box className={styles.container}>
        <div className={styles.imgContainer}>
          <img
            src={"assets/images/expertise/video2D3D.png"}
            srcSet={"assets/images/expertise/video2D3D.png"}
            alt="logo"
            loading="lazy"
            className={styles.img1}
          />
          <img
            src={"assets/images/expertise/studiographic/img2.png"}
            srcSet={"assets/images/expertise/studiographic/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/expertise/studiographic/img3.png"}
            srcSet={"assets/images/expertise/studiographic/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
        </div>
        <Box className={styles.textContainer}>
          <Box
            className="flex-start"
            onMouseOver={() => setIconSrc("assets/icons/circle.svg")}
            onMouseOut={() => setIconSrc("assets/icons/circleBlue.svg")}
            onClick={backHandler}
          >
            <IonIcon icon={iconSrc} className={styles.icon2} />
            <Typography className={styles.tg2}>Vidéo 2D / 3D</Typography>
          </Box>
          <Typography className={styles.tg3}>
            Nous savons créer des des vidéos 2D/ 3D animése à tous secteurs
            d’activité : industrie, santé, collectivités, banque, assurance,
            logistique, distribution…. Nous créons des vidéos sur-mesure, selon
            vos attentes, vos besoins et vos contraintes.
          </Typography>
        </Box>
      </Box>
      <Box className={styles.backIconContainer}>
        <IonIcon
          icon={"assets/icons/arrowLeft.svg"}
          className={styles.backIcon}
          onClick={backHandler}
        />
        <IonIcon
          icon={"assets/icons/back.svg"}
          className={styles.backIconMobile}
          onClick={backHandler}
        />
      </Box>
    </Box>
  );
};

export default Section33;
