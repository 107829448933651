import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Carousel.module.scss";

const Carousel = (props: any) => {
  const { images, slidesToShow = 4, rtl = false } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    rtl: rtl,
    responsive: [
      {
        breakpoint: 576, // xs
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // sm
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // md
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // xl
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {images.map((image: string, index: number) => (
        <div className={styles.carouselSlide} key={index}>
          <img
            src={image}
            className={styles.carouselImage}
            alt={`Image ${index + 1}`}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
