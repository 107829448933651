import config from "../config/config";

export const addSubscriber = (email) => {
  fetch(config.apiUrl + "/subscribers", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  })
    .then((response) => {
      if (response.ok) {
        console.log("subscriber was added successfully.");
      } else {
        console.log("Failed to add subscriber.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const getAll = async (page, perPage) => {
  const searchParams = new URLSearchParams({ page, perPage });
  const apiUrl =
    page && perPage
      ? `${config.apiUrl + "/subscribers"}?${searchParams.toString()}`
      : `${config.apiUrl + "/subscribers"}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};
