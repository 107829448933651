import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import Section31 from "./components/Section31";
import Section32 from "./components/Section32";
import Section33 from "./components/Section33";
import Section34 from "./components/Section34";
import styles from "./Section3.module.scss";

const Section3 = (props: any) => {
  const [showBranding, setShowBranding] = useState(false);
  const [showWebDesign, setShowWebDesign] = useState(false);
  const [showVideo2D3D, setShowVideo2D3D] = useState(false);
  const [showProductionAudioVisuelle, setShowProductionAudioVisuelle] =
    useState(false);
  const [iconSrc, setIconSrc] = useState("assets/icons/circle.svg");

  const handleClick = () => {
    const element = document.getElementById("s1");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (props.sectionId === "branding") {
      setShowBranding(true);
      setShowVideo2D3D(false);
      setShowProductionAudioVisuelle(false);
      setShowWebDesign(false);
    }
    if (props.sectionId === "webdesign") {
      setShowWebDesign(true);
      setShowBranding(false);
      setShowVideo2D3D(false);
      setShowProductionAudioVisuelle(false);
    }
    if (props.sectionId === "video2D3D") {
      setShowVideo2D3D(true);
      setShowBranding(false);
      setShowProductionAudioVisuelle(false);
      setShowWebDesign(false);
    }
    if (props.sectionId === "productionaudiovisuelle") {
      setShowProductionAudioVisuelle(true);
      setShowBranding(false);
      setShowWebDesign(false);
      setShowVideo2D3D(false);
    }
  }, [props.sectionId]);
  return (
    <Box className={styles.container} id="studioGraphique">
      <Box
        className={styles.title + " flex-start"}
        onMouseOver={() => setIconSrc("assets/icons/icon3.svg")}
        onMouseOut={() => setIconSrc("assets/icons/circle.svg")}
        onClick={handleClick}
      >
        <IonIcon icon={iconSrc} className={styles.icon1} />
        <Typography className={styles.tg1}>Studio Graphique</Typography>
      </Box>
      {!showBranding &&
        !showWebDesign &&
        !showVideo2D3D &&
        !showProductionAudioVisuelle && (
          <Box className={styles.content}>
            <ul className={styles.circleList}>
              <li onClick={() => setShowBranding(true)}>
                <span>Branding</span>
              </li>
              <li onClick={() => setShowWebDesign(true)}>
                <span>Web Design</span>
              </li>
              <li onClick={() => setShowVideo2D3D(true)}>
                <span>Vidéo 2D / 3D</span>
              </li>
              <li onClick={() => setShowProductionAudioVisuelle(true)}>
                <span>Production Audiovisuelle</span>
              </li>
            </ul>
            <div className={styles.imgContainer}>
              <img
                src={"assets/images/expertise/studiographic/img1.png"}
                srcSet={"assets/images/expertise/studiographic/img1.png"}
                alt="logo"
                loading="lazy"
                className={styles.img1}
              />
              <img
                src={"assets/images/expertise/studiographic/img2.png"}
                srcSet={"assets/images/expertise/studiographic/img2.png"}
                alt="logo"
                loading="lazy"
                className={styles.img2}
              />
              <img
                src={"assets/images/expertise/studiographic/img3.png"}
                srcSet={"assets/images/expertise/studiographic/img3.png"}
                alt="logo"
                loading="lazy"
                className={styles.img3}
              />
              </div>
          </Box>
        )}
      {showBranding && <Section31 backHandler={() => setShowBranding(false)} />}
      {showWebDesign && (
        <Section32 backHandler={() => setShowWebDesign(false)} />
      )}
      {showVideo2D3D && (
        <Section33 backHandler={() => setShowVideo2D3D(false)} />
      )}
      {showProductionAudioVisuelle && (
        <Section34 backHandler={() => setShowProductionAudioVisuelle(false)} />
      )}
    </Box>
  );
};

export default Section3;
