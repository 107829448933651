import {
  AppBar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import styles from "./Footer.module.scss";
import { routes } from "../../routes";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import MenuIcon from "@mui/icons-material/Menu";
import { MapContainer } from "../map/MapContainer";
import { addSubscriber } from "../../services/subscriber";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sectionId, setSectionId] = useState("");
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [iconSrc, setIconSrc] = useState("assets/icons/arrowCircleLeft.svg");
  const [anchorElSubMenu, setAnchorElSubMenu] = useState<null | HTMLElement>(
    null
  );
  const [email, setEmail] = useState("");
  const [anchorElSubSubMenu1, setAnchorElSubSubMenu1] =
    useState<null | HTMLElement>(null);
  const [anchorElSubSubMenu2, setAnchorElSubSubMenu2] =
    useState<null | HTMLElement>(null);

  const handleOpenSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSubMenu(event.currentTarget);
  };
  const handleOpenSubSubMenu1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSubSubMenu1(event.currentTarget);
  };
  const handleOpenSubSubMenu2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSubSubMenu2(event.currentTarget);
  };
  const handleCloseSubMenu = (event: any) => {
    setAnchorElSubMenu(null);
  };
  const handleCloseSubSubMenu1 = (event: any) => {
    setAnchorElSubSubMenu1(null);
  };
  const handleCloseSubSubMenu2 = (event: any) => {
    setAnchorElSubSubMenu2(null);
  };
  const onClickHandler = (e: any, item: any) => {
    if (item.subMenu && item.subMenu.length) {
      item.name === "Studio Graphique"
        ? handleOpenSubSubMenu1(e)
        : handleOpenSubSubMenu2(e);
      return;
    }
    setIconSrc("assets/icons/arrowCircleRight.svg");
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const goTo = (sectionId: string) => {
    setSectionId(sectionId);
  };
  const handleCloseNavMenu = (event: any) => {
    setAnchorElNav(null);
  };
  const onChangeEmailHandler = (event: any) => {
    setEmail(event.target.value);
  };
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    addSubscriber({ email: email });
    setEmail("");
  };
  useEffect(() => {
    setAnchorElNav(null);
    setAnchorElSubMenu(null);
    setAnchorElSubSubMenu1(null);
    setAnchorElSubSubMenu2(null);
    const state = { sectionId: sectionId };
    navigate(location.pathname, { state });
  }, [sectionId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openLinkInNewTab = (url: string) => {
    console.log("ee");
    window.open(url, "_blank");
  };

  return (
    <Box className={styles.footer}>
      <Container maxWidth="xl" className={styles.mainContainer}>
        <Toolbar disableGutters className={styles.navbar}>
          <img
            src={"assets/images/footer.png"}
            srcSet={"assets/images/footer.png"}
            alt="logo"
            loading="lazy"
          />
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{ color: "white" }}
            >
              <MenuIcon className={styles.menuIcon} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((route) => (
                <NavLink
                  className={styles.navlink}
                  key={route.name}
                  to={route.path}
                  onClick={scrollToTop}
                >
                  <MenuItem key={route.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{route.name}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {routes.map((route) => (
                <NavLink
                  className={styles.navlink}
                  key={route.name}
                  to={route.path}
                  onClick={scrollToTop}
                >
                  <Button
                    className={
                      route.name === "Expertise"
                        ? styles.menuItem + " " + styles.menuItemExpertise
                        : styles.menuItem
                    }
                    key={route.name}
                    onClick={
                      route.subMenu && route.subMenu.length
                        ? handleOpenSubMenu
                        : handleCloseNavMenu
                    }
                    sx={{ my: 2, color: "white" }}
                    endIcon={
                      route.subMenu && route.subMenu.length ? (
                        <IonIcon
                          icon={
                            Boolean(anchorElSubMenu)
                              ? "assets/icons/icon1.svg"
                              : "assets/icons/icon2.svg"
                          }
                          style={{ width: "18px", height: "18px" }}
                          className={
                            Boolean(anchorElSubMenu) ? "" : styles.endIcon
                          }
                        />
                      ) : null
                    }
                  >
                    {route.name}
                  </Button>
                  {route.subMenu && route.subMenu.length && (
                    <Menu
                      anchorEl={anchorElSubMenu}
                      open={Boolean(anchorElSubMenu)}
                      onClose={handleCloseSubMenu}
                      className={styles.subMenu}
                      classes={{
                        paper: styles.subMenu,
                      }}
                    >
                      {route.subMenu.map((item) => (
                        <>
                          <MenuItem>
                            <Typography
                              onClick={() => goTo(item.sectionId)}
                              className={styles.subMenuText}
                            >
                              {item.name}
                            </Typography>
                            {(item.name === "Studio Graphique" ||
                              item.name === "Studio Digital") && (
                              <ListItemIcon
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IonIcon
                                  icon={iconSrc}
                                  className={styles.icon}
                                  onClick={(e) => onClickHandler(e, item)}
                                />
                              </ListItemIcon>
                            )}
                          </MenuItem>
                          {item.subMenu && item.subMenu.length && (
                            <Menu
                              anchorEl={
                                item.name === "Studio Graphique"
                                  ? anchorElSubSubMenu1
                                  : anchorElSubSubMenu2
                              }
                              open={
                                item.name === "Studio Graphique"
                                  ? Boolean(anchorElSubSubMenu1)
                                  : Boolean(anchorElSubSubMenu2)
                              }
                              onClose={
                                item.name === "Studio Graphique"
                                  ? handleCloseSubSubMenu1
                                  : handleCloseSubSubMenu2
                              }
                              className={styles.subMenu}
                              classes={{ paper: styles.subMenu }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              {item.subMenu.map((i) => (
                                <MenuItem onClick={() => goTo(i.sectionId)}>
                                  {i.name}
                                </MenuItem>
                              ))}
                            </Menu>
                          )}
                        </>
                      ))}
                    </Menu>
                  )}
                </NavLink>
              ))}
            </Box>
            <Divider className="divider" />
          </Box>
        </Toolbar>
        <Box className={styles.container}>
          <Box className={styles.formContainer}>
            <Typography className={styles.tg1}>
              Inscription à notre newsletter
            </Typography>
            <form onSubmit={onSubmitHandler} className={styles.form}>
              <input
                className={styles.input}
                type="text"
                onChange={onChangeEmailHandler}
                value={email}
              />
            </form>
            <Typography className={styles.tg2}>
              Restez à jour et ne manquez pas nos conseils et nouvelles.
            </Typography>
            <Typography className={styles.tg3}>
              © 2023 Emotion Colors. Tous droits réservés
            </Typography>
            <Box className={styles.socialMediaContainer}>
              <IonIcon
                icon={"assets/icons/facebook.svg"}
                className={styles.socialMediaIcon}
                onClick={() =>
                  openLinkInNewTab("https://www.facebook.com/emotionscolors")
                }
              />
              <IonIcon
                icon={"assets/icons/instgram.svg"}
                className={styles.socialMediaIcon}
                onClick={() =>
                  openLinkInNewTab("https://www.instagram.com/emotion_colors/")
                }
              />
              <IonIcon
                icon={"assets/icons/linkedin.svg"}
                className={styles.socialMediaIcon}
                onClick={() =>
                  openLinkInNewTab("https://www.linkedin.com/company/18823081")
                }
              />
            </Box>
          </Box>
          <Box className={styles.carousel}>
            <MapContainer />
          </Box>
          <Box className={styles.socialMediaContainerMobile}>
            <IonIcon
              icon={"assets/icons/facebook.svg"}
              className={styles.socialMediaIcon}
              onClick={() =>
                openLinkInNewTab("https://www.facebook.com/emotionscolors")
              }
            />
            <IonIcon
              icon={"assets/icons/instgram.svg"}
              className={styles.socialMediaIcon}
              onClick={() =>
                openLinkInNewTab("https://www.instagram.com/emotion_colors/")
              }
            />
            <IonIcon
              icon={"assets/icons/linkedin.svg"}
              className={styles.socialMediaIcon}
              onClick={() =>
                openLinkInNewTab("https://www.linkedin.com/company/18823081")
              }
            />
          </Box>
          <Typography className={styles.tg4}>
            © 2023 Emotion Colors. Tous droits réservés
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
export default Footer;
