import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Clients from "./Clients";
import styles from "./Section2.module.scss";
import { useEffect, useState } from "react";
import { getAll } from "../../../services/admin/project";
import { getAllVideos } from "../../../services/admin/video";

const Section2 = () => {
  const perPage = 16;
  const [projects, setProjects] = useState([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getAll(page, perPage)
      .then((data) => {
        setLoading(false);
        setProjects(data.rows);
        setNumberOfPages(Math.ceil(data.count / perPage));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    getAllVideos()
      .then((data) => {
        setVideos(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, perPage]);

  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };
  return (
    <>
      {projects && projects.length > 0 && (
        <Box className={styles.section2}>
          <Box className={styles.titleContainer}>
            <img
              src={"assets/images/portfolio/img2.png"}
              srcSet={"assets/images/portfolio/img2.png"}
              alt="logo"
              loading="lazy"
              className={styles.img1}
            />
            <Typography className={styles.tg1}>
              WE DELIVER VALUE, CONNECT WITH US
            </Typography>
            <img
              src={"assets/images/portfolio/img2.png"}
              srcSet={"assets/images/portfolio/img2.png"}
              alt="logo"
              loading="lazy"
              className={styles.img1}
            />
          </Box>
          <img
            src={"assets/images/portfolio/img2.png"}
            srcSet={"assets/images/portfolio/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <Clients
            clients={projects}
            videos={videos}
            page={page}
            numberOfPages={numberOfPages}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};

export default Section2;
