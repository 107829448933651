import Layout from "../components/layout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Typography,
  Card,
} from "@mui/material";
import { useState, useEffect } from "react";
import { getAll } from "../../../services/subscriber";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Toast } from "../components/toast/Toast";

const Newsletter = () => {
  const navigate = useNavigate();
  const [subscribers, setSubscribers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setLoading(true);
    getAll(page + 1, rowsPerPage)
      .then((data) => {
        setSubscribers(data.rows);
        setCount(data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, rowsPerPage]);
  const handleExport = () => {
    getAll()
      .then((data) => {
        const extractedData = data.map(({ email }) => ({ email }));
        const ws = XLSX.utils.json_to_sheet(extractedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "subscribers.xlsx");
        handleOpenToast("Les abonnés ont été exportés avec succès!", "success");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        handleOpenToast("Il y a un problème!", "error");
      });
  };
  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      {!loading && subscribers && subscribers.length > 0 && (
        <>
          <Box display="flex" justifyContent="flex-end" mb="20px">
            {/*<Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => navigate("/admin/newsletter/send")}
              style={{ marginRight: "10px" }}
            >
              Envoyer un newsletter
            </Button>*/}
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleExport}
            >
              Exporter
            </Button>
          </Box>
          <div>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ marginBottom: "10px", textAlign: "start" }}
            >
              Liste des abonnés au newsletter
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Adresse E-mail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscribers.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1 + rowsPerPage * page}</TableCell>
                      <TableCell>{item.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page:"
              labelDisplayedRows={function defaultLabelDisplayedRows({
                from,
                to,
                count,
              }) {
                return `${from}–${to} sur ${
                  count !== -1 ? count : `plus que ${to}`
                }`;
              }}
            />
          </div>
        </>
      )}
      {!loading && subscribers && subscribers.length === 0 && (
        <Card style={{ padding: "30px" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
            style={{ marginBottom: "20px" }}
          >
            Il n'y a aucun abonné au newsletter
          </Typography>
        </Card>
      )}
      {loading && <CircularProgress style={{ marginTop: "30px" }} />}
    </Layout>
  );
};

export default Newsletter;
