import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3/Section3";
import Section4 from "./components/Section4/Section4";
import Section5 from "./components/Section5";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Expertise = () => {
  const location = useLocation();
  const state = location.state;
  const sectionId = state ? state.sectionId : "";
  useEffect(() => {
    if (
      [
        "branding",
        "webdesign",
        "video2D3D",
        "productionaudiovisuelle",
      ].includes(sectionId)
    ) {
      const element = document.getElementById("studioGraphique");
      if (element) element.scrollIntoView({ behavior: "smooth" });
      return;
    }
    const element = document.getElementById(sectionId);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, [sectionId]);

  return (
    <>
      <Header />
      <Section1 />
      <Section2 />
      <Section3 sectionId={sectionId} />
      <Section4 />
      <Section5 />
      <Footer />
    </>
  );
};

export default Expertise;
