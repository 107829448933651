import { Button, Container } from "@mui/material";
import Chip from "@mui/material/Chip";
import "./style.scss";

function FileUpload(props) {
  const {
    fileNames = [],
    setFileNames = null,
    selectedFiles,
    setSelectedFiles,
    error,
    multiple = false,
    label,
    acceptedTypes,
  } = props;

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      setSelectedFiles(files);
      const x = multiple ? [...fileNames] : [];
      files.map((file) => {
        x.push(file.name);
      });
      setFileNames(x);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleRemoveFile = (fileName) => {
    const updatedNames = fileNames.filter((name) => name !== fileName);
    setFileNames(updatedNames);
    const updatedFiles = selectedFiles.filter((file) =>
      updatedNames.includes(file.name)
    );
    setSelectedFiles(updatedFiles);
  };

  return (
    <Container className="fileUpload">
      <input
        accept={acceptedTypes}
        type="file"
        style={{ display: "none" }}
        id="file-upload"
        onChange={handleFileChange}
        multiple={multiple}
      />
      <label htmlFor="file-upload">
        <Button
          variant="outlined"
          component="span"
          color={!error ? "primary" : "error"}
        >
          {label}
        </Button>
      </label>
      <div className={"fileName"}>
        {fileNames.length > 0 &&
          fileNames.map((file, index) => (
            <Chip
              label={file}
              onDelete={() => handleRemoveFile(file)}
              style={{ marginRight: "5px" }}
            />
          ))}
      </div>
    </Container>
  );
}

export default FileUpload;
