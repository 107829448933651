import config from "../config/config";

export const sendEmail = (mailOptions) => {
  fetch(config.apiUrl + "/send-email", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(mailOptions),
  })
    .then((response) => {
      if (response.ok) {
        console.log("Email sent successfully.");
      } else {
        console.log("Failed to send email.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const sendNewsletter = (mailOptions) => {
  fetch(config.apiUrl + "/send-newsletter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(mailOptions),
  })
    .then((response) => {
      if (response.ok) {
        console.log("Newsletter sent successfully.");
      } else {
        console.log("Failed to send newsletter.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};
