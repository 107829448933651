import { useState } from "react";
import FileUpload from "../components/upload/FileUpload";
import { Button, CardActions, LinearProgress } from "@mui/material";
import "./style.scss";
import { addVideo } from "../../../services/admin/video";
import config from "../../../config/config";

function AddVideo(props) {
  const { getVideos } = props;
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [errors, setErrors] = useState({});
  const [add, setAdd] = useState(false);

  const addVideosHandler = async () => {
    setAdd(true);
    const fetchPromises = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("video", files[i]);
      fetchPromises.push(
        fetch(config.apiUrl + "/videos", {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              console.log("video was added successfully.");
            } else {
              console.log("Failed to add video.");
            }
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          })
      );
    }
    try {
      // Wait for all fetch promises to resolve
      await Promise.all(fetchPromises);
      console.log("All fetch requests completed.");

      // Call your function after all fetch requests are done
      setFiles([]);
      setFileNames([]);
      setErrors([]);
      getVideos();
      setAdd(false);
    } catch (error) {
      console.error("Error in one or more fetch requests:", error);
    }
  };
  const annulateHandler = () => {
    setFiles([]);
    setFileNames([]);
    setErrors({});
  };
  return (
    <>
      <FileUpload
        selectedFiles={files}
        setSelectedFiles={setFiles}
        error={errors.videos}
        setFileNames={setFileNames}
        fileNames={fileNames}
        label="Séléctioner des vidéos"
        multiple={true}
        acceptedTypes="video/*"
      />
      {add && (
        <LinearProgress style={{ marginTop: "30px", marginBottom: "30px" }} />
      )}
      {files.length > 0 && (
        <CardActions className="btnContainer">
          {!add && (
            <Button variant="" color="primary" onClick={annulateHandler}>
              Annuler
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={addVideosHandler}
            disabled={add}
          >
            Ajouter
          </Button>
        </CardActions>
      )}
    </>
  );
}

export default AddVideo;
