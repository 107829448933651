import ArticleIcon from "@mui/icons-material/Article";
import EmailIcon from "@mui/icons-material/Email";
import FolderIcon from "@mui/icons-material/Folder";
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

export const menu = [
  { name: "Articles", icon: <ArticleIcon />, path: "/admin/articles" },
  { name: "Newsletter", icon: <EmailIcon />, path: "/admin/newsletter" },
  { name: "Projects", icon: <FolderIcon />, path: "/admin/projects" },
  { name: "Videos", icon: <PersonalVideoIcon />, path: "/admin/videos" },
];
