import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import styles from "./Header.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { IonIcon } from "@ionic/react";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState<null | HTMLElement>(
    null
  );
  const [anchorElSubSubMenu1, setAnchorElSubSubMenu1] =
    useState<null | HTMLElement>(null);
  const [anchorElSubSubMenu2, setAnchorElSubSubMenu2] =
    useState<null | HTMLElement>(null);

  const [iconSrc, setIconSrc] = useState("assets/icons/arrowCircleLeft.svg");
  const [sectionId, setSectionId] = useState("");
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event: any) => {
    setAnchorElNav(null);
  };

  const handleOpenSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSubMenu(event.currentTarget);
  };
  const handleOpenSubSubMenu1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSubSubMenu1(event.currentTarget);
  };
  const handleOpenSubSubMenu2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSubSubMenu2(event.currentTarget);
  };
  const handleCloseSubMenu = (event: any) => {
    setAnchorElSubMenu(null);
  };
  const handleCloseSubSubMenu1 = (event: any) => {
    setAnchorElSubSubMenu1(null);
  };
  const handleCloseSubSubMenu2 = (event: any) => {
    setAnchorElSubSubMenu2(null);
  };

  const goTo = (sectionId: string) => {
    setSectionId(sectionId);
  };

  React.useEffect(() => {
    setAnchorElNav(null);
    setAnchorElSubMenu(null);
    setAnchorElSubSubMenu1(null);
    setAnchorElSubSubMenu2(null);
    const state = { sectionId: sectionId };

    navigate(location.pathname, { state });
  }, [sectionId]);

  const onClickHandler = (e: any, item: any) => {
    if (item.subMenu && item.subMenu.length) {
      item.name === "Studio Graphique"
        ? handleOpenSubSubMenu1(e)
        : handleOpenSubSubMenu2(e);
      return;
    }
    setIconSrc("assets/icons/arrowCircleRight.svg");
  };

  return (
    <AppBar position="absolute" className={styles.appbar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className="sp-content">
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={"assets/images/logo.png"}
              srcSet={"assets/images/logo.png"}
              alt="logo"
              loading="lazy"
              className={styles.logo}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <img
              src={"assets/images/logo.png"}
              srcSet={"assets/images/logo.png"}
              alt="logo"
              loading="lazy"
              className={styles.logo}            />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((route) => (
                <NavLink
                  className={styles.navlink}
                  key={route.name}
                  to={route.path}
                >
                  <MenuItem key={route.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{route.name}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {routes.map((route) => {
                return (
                  <NavLink
                    className={styles.navlink}
                    key={route.name}
                    to={route.path}
                  >
                    <Button
                      className={
                        route.name === "Expertise"
                          ? styles.menuItem + " " + styles.menuItemExpertise
                          : styles.menuItem
                      }
                      key={route.name}
                      onClick={
                        route.subMenu && route.subMenu.length
                          ? handleOpenSubMenu
                          : handleCloseNavMenu
                      }
                      sx={{ my: 2, color: "white" }}
                      endIcon={
                        route.subMenu && route.subMenu.length ? (
                          <IonIcon
                            icon={
                              Boolean(anchorElSubMenu)
                                ? "assets/icons/icon1.svg"
                                : "assets/icons/icon2.svg"
                            }
                            style={{ width: "18px", height: "18px" }}
                            className={
                              Boolean(anchorElSubMenu) ? "" : styles.endIcon
                            }
                          />
                        ) : null
                      }
                    >
                      {route.name}
                    </Button>
                    {route.subMenu && route.subMenu.length && (
                      <Menu
                        anchorEl={anchorElSubMenu}
                        open={Boolean(anchorElSubMenu)}
                        onClose={handleCloseSubMenu}
                        className={styles.subMenu}
                        classes={{
                          paper: styles.subMenu,
                        }}
                      >
                        {route.subMenu.map((item) => (
                          <>
                            <MenuItem>
                              <Typography
                                onClick={() => goTo(item.sectionId)}
                                className={styles.subMenuText}
                              >
                                {item.name}
                              </Typography>
                              {(item.name === "Studio Graphique" ||
                                item.name === "Studio Digital") && (
                                <ListItemIcon
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IonIcon
                                    icon={iconSrc}
                                    className={styles.icon}
                                    onClick={(e) => onClickHandler(e, item)}
                                  />
                                </ListItemIcon>
                              )}
                            </MenuItem>
                            {item.subMenu && item.subMenu.length && (
                              <Menu
                                anchorEl={
                                  item.name === "Studio Graphique"
                                    ? anchorElSubSubMenu1
                                    : anchorElSubSubMenu2
                                }
                                open={
                                  item.name === "Studio Graphique"
                                    ? Boolean(anchorElSubSubMenu1)
                                    : Boolean(anchorElSubSubMenu2)
                                }
                                onClose={
                                  item.name === "Studio Graphique"
                                    ? handleCloseSubSubMenu1
                                    : handleCloseSubSubMenu2
                                }
                                className={styles.subMenu}
                                classes={{ paper: styles.subMenu }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                style={{ marginLeft: "20px" }}
                              >
                                {item.subMenu.map((i) => (
                                  <MenuItem onClick={() => goTo(i.sectionId)}>
                                    {i.name}
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                          </>
                        ))}
                      </Menu>
                    )}
                  </NavLink>
                );
              })}
            </Box>
            <Divider className="divider" />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
