import styles from "./ImageWithOverlay.module.scss";

const ImageWithOverlay = (props: any) => {
  const { image, title, onClickHandler = () => {} } = props;
  return (
    <div className={styles.container} onClick={onClickHandler}>
      <div className={`${styles.overlay} ${styles.zoom}`}>
        <span className={styles.text}>
          {" "}
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </span>
      </div>
      <img className={styles.image} src={image} alt="Image" />
    </div>
  );
};

export default ImageWithOverlay;
