import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import About from "./components/About";
import Elephant from "./components/Elephant";
import Expertises from "./components/Expertises";
import FullScreenVideo from "./components/FullScreenVideo";
import Partners from "./components/Partners";
import Slider from "./components/Slider";

const Home = () => {
  return (
    <>
      <Header />
      <FullScreenVideo />
      <About />
      <Expertises />
      <Elephant />
      <Slider />
      <Partners />
      <Footer />
    </>
  );
};

export default Home;
