import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardActions,
} from "@mui/material";
import Layout from "../components/layout";
import ImageUpload from "../components/upload/ImageUpload";
import { Toast } from "../components/toast/Toast";
import { addArticle } from "../../../services/admin/article";
import "./style.scss";

function AddArticle() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [imageNames, setImageNames] = useState([]);

  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!title.trim()) {
      validationErrors.title = "Le titre est obligatoire";
    }
    if (!description.trim()) {
      validationErrors.description = "La description est obligatoire";
    }
    if (!category.trim()) {
      validationErrors.category = "La catégorie est obligatoire";
    }
    if (!imageNames.length) {
      validationErrors.image = "L'image est obligatoire";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("image", image[0]);
    addArticle(formData);
    handleOpenToast("L'article a été ajouté avec succès!", "success");
    setTitle("");
    setDescription("");
    setCategory("");
    setImage([]);
    setImageNames([]);
    setErrors({});
  };

  const backHandler = () => {
    navigate("/admin/articles");
  };

  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      <Card style={{ padding: "30px" }}>
        <Typography variant="h5" mb="30px" gutterBottom>
          Ajouter un article
        </Typography>
        <ImageUpload
          selectedFiles={image}
          setSelectedFiles={setImage}
          error={errors.image}
          setImageNames={setImageNames}
          imageNames={imageNames}
        />
        {errors.image && (
          <Typography className="errorText">{errors.image}</Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Titre"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handleTitleChange}
            margin="normal"
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            label="Catégorie"
            variant="outlined"
            fullWidth
            value={category}
            onChange={handleCategoryChange}
            margin="normal"
            error={!!errors.category}
            helperText={errors.category}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            margin="normal"
            error={!!errors.description}
            helperText={errors.description}
          />
          <CardActions className="btnContainer">
            <Button
              type="submit"
              variant=""
              color="primary"
              onClick={backHandler}
            >
              Retour
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Ajouter
            </Button>
          </CardActions>
        </form>
      </Card>
    </Layout>
  );
}

export default AddArticle;
