import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section32.module.scss";
import { useState } from "react";

const Section32 = (props: any) => {
  const { backHandler } = props;
  const [iconSrc, setIconSrc] = useState("assets/icons/circleBlue.svg");
  return (
    <Box id="webdesign" className={styles.mainContainer}>
      <Box className={styles.container}>
        <div className={styles.imgContainer}>
          <img
            src={"assets/images/expertise/webdesign.png"}
            srcSet={"assets/images/expertise/webdesign.png"}
            alt="logo"
            loading="lazy"
            className={styles.img1}
          />
          <img
            src={"assets/images/expertise/studiographic/img2.png"}
            srcSet={"assets/images/expertise/studiographic/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/expertise/studiographic/img3.png"}
            srcSet={"assets/images/expertise/studiographic/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
        </div>
        <Box className={styles.textContainer}>
          <Box
            className="flex-start"
            onMouseOver={() => setIconSrc("assets/icons/circle.svg")}
            onMouseOut={() => setIconSrc("assets/icons/circleBlue.svg")}
            onClick={backHandler}
          >
            <IonIcon icon={iconSrc} className={styles.icon2} />
            <Typography className={styles.tg2}>Web Design</Typography>
          </Box>
          <Typography className={styles.tg3}>
            Notre équipe met en oeuvre des compétences en UX / UI design, en
            design graphique pour que le rendu graphique de vos pages web
            combine le respect de vos valeurs et de votre image, l’utilisabilité
            des interfaces, et vienne contribuer à la réussite de vos objectifs
            marketing.
          </Typography>
        </Box>
      </Box>
      <Box className={styles.backIconContainer}>
        <IonIcon
          icon={"assets/icons/arrowLeft.svg"}
          className={styles.backIcon}
          onClick={backHandler}
        />
        <IonIcon
          icon={"assets/icons/back.svg"}
          className={styles.backIconMobile}
          onClick={backHandler}
        />
      </Box>
    </Box>
  );
};

export default Section32;
