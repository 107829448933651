import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section2.module.scss";
import { useState } from "react";

const Section2 = () => {
  const [iconSrc, setIconSrc] = useState("assets/icons/circle.svg");
  const handleClick = () => {
    const element = document.getElementById("s1");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box className={styles.container} id="communication360">
      <Box width="100%">
        <Box
          className={styles.title + " flex-start"}
          onMouseOver={() => setIconSrc("assets/icons/icon3.svg")}
          onMouseOut={() => setIconSrc("assets/icons/circle.svg")}
          onClick={handleClick}
        >
          <IonIcon icon={iconSrc} className={styles.icon1} />
          <Typography className={styles.tg2}>Communication 360°</Typography>
        </Box>
        <Box className={styles.content}>
          <img
            src={"assets/images/expertise/img3.png"}
            srcSet={"assets/images/expertise/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img4}
          />
          <Box className={styles.content2}>
            <Box className={styles.textContainer}>
              <Typography className={styles.tg3}>
                Notre stratégie de Communication 360 comprend les éléments
                suivants :{" "}
                <img
                  src={"assets/images/expertise/img7.png"}
                  srcSet={"assets/images/expertise/img7.png"}
                  alt="logo"
                  loading="lazy"
                  className={styles.img5}
                />
              </Typography>
            </Box>
            <ul>
              <li>
                La publicité traditionnelle (TV, radio, presse, affichage urbain
                )
              </li>
              <li>Le marketing direct (mailing, emailing, SMS)</li>
              <li>
                Le marketing digital (réseaux sociaux, SEO, SEA, display, email
                marketing)
              </li>
              <li>Les relations publiques et les événements</li>
              <li>
                Les éléments de visibilité nécessaire pour chaque campagne
                offline
              </li>
            </ul>
            <Box className={styles.textContainer2}>
              <img
                src={"assets/images/expertise/img8.png"}
                srcSet={"assets/images/expertise/img8.png"}
                alt="logo"
                loading="lazy"
                className={styles.img3}
              />
              <Box mr="5px">
                <Typography className={styles.tg4}>
                  Notre accompagnement
                </Typography>
                <Typography className={styles.tg5}> de marque est</Typography>
              </Box>
              <Typography className={styles.tg6}>360°</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Section2;
