import * as React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";
import "./style.scss";
import { useNavigate } from "react-router-dom";

export default function Layout(props) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = () => {
    localStorage.setItem("isConnected", "false");
    navigate("/admin");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} onLogout={onLogout} />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Content>{props.children}</Content>
    </Box>
  );
}
