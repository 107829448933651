import { useState } from "react";
import { Box, Grid } from "@mui/material";
import ImageWithOverlay from "../../../components/imageWithOverlay/ImageWithOverlay";
import ClientDetails from "./ClientDetails";
import config from "../../../config/config";
import Video from "../../../components/video/Video";
import { Pagination, PaginationItem } from "@mui/material";

const Clients = (props: any) => {
  const {
    left = true,
    clients,
    videos,
    numberOfPages,
    page,
    handlePageChange,
  } = props;
  const [currentClient, setCurrentClient] = useState<any>(null);
  const onShowDetailsHandler = (client: any) => {
    setCurrentClient(client);
  };
  const middleIndex = Math.floor(clients.length / 2);

  const clients1 = clients.length > 8 ? clients.slice(0, middleIndex) : clients;
  const clients2 = clients.length > 8 ? clients.slice(middleIndex): [];
  return (
    <Box style={{ marginBottom: "20px" }}>
      {!currentClient && (
        <>
          <Box style={{ marginBottom: "20px" }}>
            <Grid container spacing={2}>
              {clients1.map((item: any) => (
                <Grid item xs={12} sm={6} md={6}>
                  <ImageWithOverlay
                    title={item.client}
                    image={
                      config.apiUrl + "/projects/readFile/" + item.images[0]
                    }
                    onClickHandler={(e: any) => {
                      e.stopPropagation();
                      onShowDetailsHandler(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {videos.length > 0 && (
            <Box style={{ marginBottom: "20px" }}>
              <Grid container spacing={2}>
                {videos[0] && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Video title={videos[0].title} />
                  </Grid>
                )}
                {videos[1] && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Video title={videos[1].title} />
                  </Grid>
                )}
                {videos[2] && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Video title={videos[2].title} />
                  </Grid>
                )}
                {videos[3] && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Video title={videos[3].title} />
                  </Grid>
                )}
                {videos[4] && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Video title={videos[4].title} />
                  </Grid>
                )}
                {videos[5] && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Video title={videos[5].title} />
                  </Grid>
                )}
                {videos[6] && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Video title={videos[6].title} />
                  </Grid>
                )}
                {videos[7] && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Video title={videos[7].title} />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          <Box>
            <Grid container spacing={2}>
              {clients2.map((item: any) => (
                <Grid item xs={12} sm={6} md={6}>
                  <ImageWithOverlay
                    title={item.client}
                    image={
                      config.apiUrl + "/projects/readFile/" + item.images[0]
                    }
                    onClickHandler={(e: any) => {
                      e.stopPropagation();
                      onShowDetailsHandler(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {numberOfPages > 1 && (
            <Box
              id="pagination"
              display="flex"
              justifyContent="center"
              mt="40px"
            >
              <Pagination
                color="primary"
                count={numberOfPages}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem component="button" {...item} />
                )}
              />
            </Box>
          )}
        </>
      )}
      {currentClient && (
        <ClientDetails
          client={currentClient}
          backHandler={() => setCurrentClient(null)}
        />
      )}
    </Box>
  );
};

export default Clients;
