export const marketingDigital = {
  id: "marketingDigital",
  title: "Le Marketing Digital",
  types: [
    {
      title: "Strategic Planning",
      theme: "dark",
      details: [
        "Nous travaillons étroitement avec nos clients pour élaborer une stratégie sur le long terme, depuis le positionnement de la marque, du ciblage des publics jusqu’à la mesure de la performance. Nous définissons des stratégies social media qui apportent une valeur ajoutée de marque à la conversation. <br/><br/> C’est sur cette base que nous imaginons des stratégies média cohérentes et efficaces, pour permettre d’atteindre les objectifs de la marque.",
      ],
      image: "assets/images/expertise/marketingDigital/img1.png"
    },
    {
      title: "Création de Contenu",
      theme: "light",
      details: [
        "Nous accompagnons  nos clients à définir leur présence sur le social, à élaborer une stratégie de contenu, à créer et engager une communauté et à s’accompagner des bons influenceurs, dans le but de créer des parcours au service du branding et du business.Nous créons et racontons des histoires, d’une feuille de route éditoriale  à des conversations réactives en temps réel.",
      ],
      image: "assets/images/expertise/marketingDigital/img2.png"
    },
    {
      title: "Community Management",
      theme: "dark",
      details: [
        "Pour donner une nouvelle identité à votre marque ou alimenter vos plateformes en contenus, nous prenons en charge la création de concepts originaux et la production de ce qui va incarner votre présence digitale. Nous pouvons nous charger de publier pour vous, répondre aux commentaires et aux messages privés. Facebook, Instagram, TikTok, LinkedIn, Twitter et leurs algorithmes n’ont aucun secret pour nous !",
      ],
      image: "assets/images/expertise/marketingDigital/img3.png"
    },
    {
      title: "Social Media Management",
      theme: "light",
      details: [
        "Vos réseaux sociaux sont aujourd’hui l’un des piliers de votre communication. Nos équipes créent des expériences et des contenus originaux, qui marquent les esprits, créent du désir pour la marque, pour ses produits et ses services.Notre équipe est là pour vous aider à  surfer sur la tendance !",
      ],
      image: "assets/images/expertise/marketingDigital/img4.png"
    },
    {
      title: "Reporting",
      theme: "dark",
      details: [
        "Nous réalisons des audits détaillés, tant d’un point de vue quantitatif que qualitatif. Nous traquons les conversations autour de la marque en temps réel. Nous comparons les performances des différentes plateformes du paysage social media. Nous analysons l’historique des performances organiques et paid pour comprendre ce qui importe aux différents publics, pour découvrir les contenus qui fonctionnent ou non, afin d’optimiser votre stratégie.",
      ],
      image: "assets/images/expertise/marketingDigital/img5.png"
    },
  ],
};

export const developpementWeb = {
  id: "webDev",
  title: "Développement Web",
  types: [
    {
      title: "Création d’un Site WEB",
      theme: "dark",
      details: [
        "Site vitrine : Ce type de site présente votre structure : activités, produits, services, domaines d'expertise, actualités, coordonnées...",
        "Site catalogue : ce site vous permet de promouvoir votre catalogue de produits en ligne, sans intégrer la partie vente en ligne.",
        "Site e-commerce : Il vous permet de vendre vos produits ou vos prestations sur internet. Le site e-commerce intègre un système de gestion de commande et de paiement en ligne.",
        "Site sur mesure : ce site est entièrement personnalisé avec des fonctionnalités bien précises qui répondent à vos stratégies marketing et à votre communication.",
      ],
      image: "assets/images/expertise/devWeb/img1.png"
    },
    {
      title: "Refonte d’un Site WEB",
      theme: "light",
      details: [
        "Nous vous accompagnons d’un bout à l’autre du projet : </br> </br> sur la définition du besoin, le maquettage, l’intégration, le développement et l’ajout des contenus",
      ],
      image: "assets/images/expertise/devWeb/img2.png"
    },
    {
      title: "Référencement SEO / SEA",
      theme: "dark",
      details: [
        "Nous mettons en œuvre les techniques nécessaires pour améliorer la présence de votre site internet dans les résultats des moteurs de recherche et optimiser la visibilité de votre entreprise en ligne !",
      ],
      image: "assets/images/expertise/devWeb/img3.png"
    },
    {
      title: "Maintenance de site Internet",
      theme: "light",
      details: [
        "La mise à jour technique du site : corrections des dysfonctionnements, optimisation du code ou encore mises à jour des plugins sur un CMS.",
        "La mise à jour du contenu : ajouts de nouveaux articles d’actualités ou de blog, optimisation sémantique d’une page afin d’améliorer son référencement naturel ou encore ajouts d’images ou de contenus multimédia",
      ],
      image: "assets/images/expertise/devWeb/img4.png"
    },
  ],
};

export const transformationDigitale = {
  id: "transDigitale",
  title: "La Transformation Digitale",
  types: [
    {
      title: "Évaluation",
      theme: "dark",
      details: [
        "L'audit et l'évaluation des technologies existantes: on peut examiner les technologies existantes et évaluer leurperformance pour identifier les domaines àaméliorer.",
      ],
      image: "assets/images/expertise/transDigitale/img1.png"
    },
    {
      title: "Définition de la Stratégie",
      theme: "light",
      details: [
        "La définition de la stratégie de transformation digitale :on vous aide à définir votre stratégie de transformation digitale en fonction de vos objectifs et des tendances du marché.",
      ],
      image: "assets/images/expertise/transDigitale/img2.png"
    },
    {
      title: "Technologies Numérique ",
      theme: "dark",
      details: [
        "La mise en place des technologies numériques: on peut aider les entreprises à intégrer de nouvelles technologies,telles que les outils de communication en ligne, les logiciels de gestion de projet et les outils de collaboration en temps réel.",
      ],
      image: "assets/images/expertise/transDigitale/img3.png"
    },
    {
      title: "La Formation des Employés",
      theme: "light",
      details: [
        "La formation des employés : on peut aider les employés à s'adapter aux nouvelles technologies et à améliorer leur compétence numérique.",
      ],
      image: "assets/images/expertise/transDigitale/img4.png"
    },
  ],
};
