import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section31.module.scss";
import { useState } from "react";

const Section31 = (props: any) => {
  const { backHandler } = props;
  const [iconSrc, setIconSrc] = useState("assets/icons/circleBlue.svg");
  return (
    <Box id="branding" className={styles.mainContainer}>
      <Box className={styles.container}>
        <div className={styles.imgContainer}>
          <img
            src={"assets/images/expertise/branding.png"}
            srcSet={"assets/images/expertise/branding.png"}
            alt="logo"
            loading="lazy"
            className={styles.img1}
          />
          <img
            src={"assets/images/expertise/studiographic/img2.png"}
            srcSet={"assets/images/expertise/studiographic/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/expertise/studiographic/img3.png"}
            srcSet={"assets/images/expertise/studiographic/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
        </div>
        <Box className={styles.textContainer}>
          <Box
            className="flex-start"
            onMouseOver={() => setIconSrc("assets/icons/circle.svg")}
            onMouseOut={() => setIconSrc("assets/icons/circleBlue.svg")}
            onClick={backHandler}
          >
            <IonIcon icon={iconSrc} className={styles.icon2} />
            <Typography className={styles.tg2}>Branding</Typography>
          </Box>
          <Typography className={styles.tg3}>
            Nous vous accompagnons dans la création de votre identité de marque
            ainsi que sur la conception et la réalisation de tous vos supports
            de communication print & web. Nous militons pour le bel ouvrage.
            Pour que vous puissiez marquer les esprits à travers l’identité de
            votre entreprise, nous vous soumettons plusieurs idées graphiques
            afin de vous aider à choisir le design correspondant à vos objectifs
            et à votre cible.Nous vous proposons un accompagnement sur les
            métiers suivants :
          </Typography>
          <Box className={styles.listContainer}>
            <img
              src={"assets/images/expertise/img8.png"}
              srcSet={"assets/images/expertise/img8.png"}
              alt="logo"
              loading="lazy"
              className={styles.img4}
            />
            <ul>
              <li>Logotype</li>
              <li>Charte graphique</li>
              <li>Papeterie</li>
              <li>Catalogue, brochure, dépliant, flyer, etc.</li>
              <li>Affiche, kakémono, totem, etc..</li>
              <li>Signalétique</li>
              <li>Rapport annuel & développement durable</li>
              <li>Magazine d’entreprise</li>
            </ul>
          </Box>
        </Box>
      </Box>
      <Box className={styles.backIconContainer}>
        <IonIcon
          icon={"assets/icons/arrowLeft.svg"}
          className={styles.backIcon}
          onClick={backHandler}
        />
        <IonIcon
          icon={"assets/icons/back.svg"}
          className={styles.backIconMobile}
          onClick={backHandler}
        />
      </Box>
    </Box>
  );
};

export default Section31;
