import { Fade, Slide, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./About.module.scss";

const About = () => {
  return (
    <Box className={styles.about}>
      <Box className="flex-center">
        <Slide
          direction="right"
          in={true}
          mountOnEnter
          unmountOnExit
          style={{ transitionDelay: "3000ms" }}
        >
          <img
            src={"assets/images/about/img3.png"}
            srcSet={"assets/images/about/img3.png"}
            alt="logo"
            loading="lazy"
            className={styles.img1}
          />
        </Slide>

        <Slide
          direction="down"
          in={true}
          mountOnEnter
          unmountOnExit
          style={{ transitionDelay: "2000ms" }}
        >
          <Box>
            <Typography className={styles.tg1}>Emotion Colors</Typography>
            <Typography className={styles.tg2}>
              Let the colors define you
            </Typography>
          </Box>
        </Slide>
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <img
            src={"assets/images/about/img4.png"}
            srcSet={"assets/images/about/img4.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
            style={{ transitionDelay: "3000ms" }}
          />
        </Slide>
      </Box>
      <Box className={styles.textContainer}>
        <Typography className={styles.tg3}>
          Nous Sommes une agence de communication PHYGITALE fondée en 2013. Nous
          offrons des services de consultation en communication digitale et
          physique pour optimiser l'impact de vos messages en ligne et hors
          ligne.
        </Typography>
      </Box>
      <Box>
        <img
          src={"assets/images/homePage/phygital.png"}
          srcSet={"assets/images/homePage/phygital.png"}
          alt="logo"
          loading="lazy"
          className={styles.img3}
        />
      </Box>
    </Box>
  );
};

export default About;
