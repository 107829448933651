import { Button, Container } from "@mui/material";
import Chip from "@mui/material/Chip";
import "./style.scss";

function ImageUpload(props) {
  const {
    imageNames = [],
    setImageNames = null,
    selectedFiles,
    setSelectedFiles,
    error,
    multiple = false,
  } = props;

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      setSelectedFiles(files);
      const x = multiple ? [...imageNames] : [];
      files.map((file) => {
        x.push(file.name);
      });
      setImageNames(x);
    } else {
      setSelectedFiles([]);
    }
  };

  const handleRemoveFile = (imageName) => {
    const updatedNames = imageNames.filter((name) => name !== imageName);
    setImageNames(updatedNames);
    const updatedFiles = selectedFiles.filter((file) =>
      updatedNames.includes(file.name)
    );
    setSelectedFiles(updatedFiles);
  };

  return (
    <Container className="imageUpload">
      <input
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        id="image-upload"
        onChange={handleFileChange}
        multiple={multiple}
      />
      <label htmlFor="image-upload">
        <Button
          variant="outlined"
          component="span"
          color={!error ? "primary" : "error"}
        >
          {multiple ? "Sélectionner des images" : "Sélectionner une image"}
        </Button>
      </label>
      <div className={"imageName"}>
        {imageNames.length > 0 &&
          imageNames.map((image, index) => (
            <Chip
              label={image}
              onDelete={() => handleRemoveFile(image)}
              style={{ marginRight: "5px" }}
            />
          ))}
      </div>
    </Container>
  );
}

export default ImageUpload;
