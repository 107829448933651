import { Box, Grid } from "@mui/material";
import MediaCard from "../../../components/card/Card";
import MainCard from "../../../components/card/MainCard";
import styles from "./Section2.module.scss";
import { useEffect, useState } from "react";
import { getAll } from "../../../services/admin/article";
import config from "../../../config/config";
import { Pagination, PaginationItem } from "@mui/material";

const Section2 = (props: any) => {
  const perPage = 7;

  const [articles, setArticles] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAll(page, perPage)
      .then((data) => {
        setLoading(false);
        setArticles(data.rows);
        setNumberOfPages(Math.ceil(data.count / perPage));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, perPage]);
  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };
  return (
    <Box className={styles.section2}>
      {articles && articles.length > 0 && (
        <>
          <Box m="30px">
            <MainCard
              data={articles[0]}
              onClick={() => props.setCurrentArticle(articles[0])}
            />
          </Box>
          <Box m="30px">
            <Grid container spacing={2}>
              {articles.slice(1).map((article: any, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <MediaCard
                    category={article.category}
                    title={article.title}
                    image={config.apiUrl + "/articles/readFile/" + article.image}
                    onClick={() => props.setCurrentArticle(article)}
                  />
                </Grid>
              ))}
            </Grid>
            {numberOfPages > 1 && (
              <Box id="pagination" display="flex" justifyContent="center" mt="40px">
                <Pagination
                  color="primary"
                  count={numberOfPages}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem component="button" {...item} />
                  )}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Section2;
