import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import ArticleDetails from "./components/ArticleDetails"

const Blog = () => {
  const [currentArticle, setCurrentArticle] = useState(null);
  return (
    <>
      <Header />
      {!currentArticle && <Section1 />}
      {!currentArticle && <Section2 setCurrentArticle={setCurrentArticle} />}
      {currentArticle && <ArticleDetails currentArticle={currentArticle}/>}
      <Footer />
    </>
  );
};

export default Blog;
