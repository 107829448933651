import config from "../../config/config";

export const addArticle = (formData) => {
  fetch(config.apiUrl + "/articles", {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("article was added successfully.");
      } else {
        console.log("Failed to add article.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};
export const getAll = async (page, perPage) => {
  const searchParams = new URLSearchParams({ page, perPage });
  const apiUrl =
  page && perPage
    ? `${config.apiUrl + "/articles"}?${searchParams.toString()}`
    : `${config.apiUrl + "/articles"}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};

export const deleteArticle = async (id) => {
  const apiUrl = `${config.apiUrl + "/articles/" + id}`;

  const response = await fetch(apiUrl, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
};

export const getArticleById = async (id) => {
  const apiUrl = `${config.apiUrl + "/articles/" + id}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};
export const updateArticle = (formData, id) => {
  fetch(config.apiUrl + "/articles/" + id, {
    method: "PUT",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("article was updated successfully.");
      } else {
        console.log("Failed to update article.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};
