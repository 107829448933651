import Box from "@mui/material/Box";
import styles from "./FullScreenVideo.module.scss";

const FullScreenVideo = () => {
  return (
    <Box className={styles.fullscreenvideo}>
      <video autoPlay loop muted>
        <source src={"assets/videos/ec.mp4"} type="video/mp4" />
      </video>
    </Box>
  );
};

export default FullScreenVideo;
