import GoogleMapReact from "google-map-react";
import "./Map.scss";
import { LocationPin } from "./LocationPin/LocationPin";
export const MapContainer = () => {
  const zoomLevel = 13; //betweeen 0 and 20
  const location = {
    address: "Rue du Pakistan 1053 La Marsa, Tunisie",
    lat: 36.877228,
    lng: 10.318207,
  };
  const mapOptions = {
    minZoom: 12,
    maxZoom: 16,
  };
  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAnQBS8OsxFFLmLzgJ_9zXHhXPfoB8Eoyc" }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
          options={mapOptions}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};
