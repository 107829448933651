import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";

const Portfolio = () => {
  return (
    <>
      <Header />
      <Section1 />
      <Section2 />
      <Footer />
    </>
  );
};

export default Portfolio;
