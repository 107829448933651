import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section1.module.scss";

const Section1 = () => {
  const goTo = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box className={styles.section1} id="s1">
      <Box className={styles.container}>
        <Box className={styles.circle}>
          {" "}
          <img
            src={"assets/images/homePage/img2.png"}
            srcSet={"assets/images/homePage/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/homePage/img1.png"}
            srcSet={"assets/images/homePage/img1.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
          <Box className={styles.textContainer}>
            <Box className="sp-content">
              <Box>
                <div
                  className={
                    styles.expertiseContainer + " " + styles.borderBottom
                  }
                  onClick={() => goTo("communication360")}
                >
                  <Typography className={styles.expertiseTitle}>
                    Communication 360°
                  </Typography>
                </div>
                <img
                  src={"assets/images/homePage/img7.png"}
                  srcSet={"assets/images/homePage/img7.png"}
                  alt="logo"
                  loading="lazy"
                  className={styles.img4}
                />
              </Box>
              <Box>
                <div
                  className={
                    styles.expertiseContainer + " " + styles.borderBottom
                  }
                  onClick={() => goTo("studioDigital")}
                >
                  <Typography className={styles.expertiseTitle}>
                    Studio Digital
                  </Typography>
                </div>
                <img
                  src={"assets/images/homePage/img8.png"}
                  srcSet={"assets/images/homePage/img8.png"}
                  alt="logo"
                  loading="lazy"
                  className={styles.img4}
                />
              </Box>
            </Box>
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>
                Fine minds think Colors.
              </Typography>
            </Box>
            <Box className="sp-content">
              <Box>
                <img
                  src={"assets/images/homePage/img9.png"}
                  srcSet={"assets/images/homePage/img9.png"}
                  alt="logo"
                  loading="lazy"
                  className={styles.img4}
                />
                <div
                  className={styles.expertiseContainer + " " + styles.borderTop}
                  onClick={() => goTo("studioGraphique")}
                >
                  <Typography className={styles.expertiseTitle}>
                    Studio Graphique
                  </Typography>
                </div>
              </Box>
              <Box>
                <img
                  src={"assets/images/homePage/img10.png"}
                  srcSet={"assets/images/homePage/img10.png"}
                  alt="logo"
                  loading="lazy"
                  className={styles.img4}
                />
                <div
                  className={styles.expertiseContainer + " " + styles.borderTop}
                  onClick={() => goTo("studioImpression")}
                >
                  <Typography className={styles.expertiseTitle}>
                    Studio D’impression
                  </Typography>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.mobileContainer}>
        <Box className={styles.circle}>
          <img
            src={"assets/images/homePage/img2.png"}
            srcSet={"assets/images/homePage/img2.png"}
            alt="logo"
            loading="lazy"
            className={styles.img2}
          />
          <img
            src={"assets/images/homePage/img1.png"}
            srcSet={"assets/images/homePage/img1.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
          <Box className={styles.textContainer}>
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>
                Fine minds think Colors.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.mainContent}>
          <div>
            <img
              src={"assets/images/homePage/img9.png"}
              srcSet={"assets/images/homePage/img9.png"}
              alt="logo"
              loading="lazy"
              style={{ marginRight: "10px" }}
              className={styles.img4}
            />
            <img
              src={"assets/images/homePage/img10.png"}
              srcSet={"assets/images/homePage/img10.png"}
              alt="logo"
              loading="lazy"
              className={styles.img4}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <div
            className={styles.expertiseContainer}
            onClick={() => goTo("communication360")}
          >
            <Typography className={styles.expertiseTitle}>
              Communication 360°
            </Typography>
          </div>
          <div
            className={styles.expertiseContainer}
            onClick={() => goTo("studioDigital")}
          >
            <Typography className={styles.expertiseTitle}>
              Studio Digital
            </Typography>
          </div>
          <div
            className={styles.expertiseContainer}
            onClick={() => goTo("studioGraphique")}
          >
            <Typography className={styles.expertiseTitle}>
              Studio Graphique
            </Typography>
          </div>
          <div
            className={styles.expertiseContainer}
            onClick={() => goTo("studioImpression")}
          >
            <Typography className={styles.expertiseTitle}>
              Studio D’impression
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Section1;
