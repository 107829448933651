import config from "../../config/config";

export const addVideo = (formData) => {
  fetch(config.apiUrl + "/videos", {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("video was added successfully.");
      } else {
        console.log("Failed to add video.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};
export const getAllVideos = async (page, perPage) => {
  const searchParams = new URLSearchParams({ page, perPage });
  const apiUrl =
  page && perPage
    ? `${config.apiUrl + "/videos"}?${searchParams.toString()}`
    : `${config.apiUrl + "/videos"}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};

export const deleteVideo = async (id) => {
  const apiUrl = `${config.apiUrl + "/videos/" + id}`;

  const response = await fetch(apiUrl, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
};

export const getVideoById = async (id) => {
  const apiUrl = `${config.apiUrl + "/videos/" + id}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};
export const updateVideo = (formData, id) => {
  fetch(config.apiUrl + "/videos/" + id, {
    method: "PUT",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("videi was updated successfully.");
      } else {
        console.log("Failed to update video.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};
