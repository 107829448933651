import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./Elephant.module.scss";
import { IonIcon } from "@ionic/react";

const Elephant = () => {
  return (
    <Box className={styles.elephant}>
      <Box className={styles.tgContainer}>
        <Typography className={styles.tg1}>
          DON'T THINK{" "}
          <IonIcon
            icon={"assets/icons/redCircle2.svg"}
            className={styles.icon1}
          />
        </Typography>
        <Typography className={styles.tg2}>BIG</Typography>
        <Typography className={styles.tg3}>THINK</Typography>
        <div className={styles.tgIconCotainer}>
          <Typography className={styles.tg4}>GIANT</Typography>
          <IonIcon
            icon={"assets/icons/redCircle2.svg"}
            className={styles.icon2}
          />
        </div>
      </Box>
      <img
        src={"assets/images/homePage/elephant.png"}
        srcSet={"assets/images/homePage/elephant.png"}
        alt="logo"
        loading="lazy"
      />
    </Box>
  );
};

export default Elephant;
