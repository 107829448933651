import { IonIcon } from "@ionic/react";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section5.module.scss";
import { useState } from "react";
import { pdfName, pdfUrl } from "../../../utils/constants/global";

const Section5 = () => {
  const [iconSrc, setIconSrc] = useState("assets/icons/circle.svg");
  const handleClick = () => {
    const element = document.getElementById("s1");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Box className={styles.container} id="studioImpression">
      <Box
        onMouseOver={() => setIconSrc("assets/icons/icon3.svg")}
        onMouseOut={() => setIconSrc("assets/icons/circle.svg")}
        onClick={handleClick}
        className={styles.titleContainer + " flex-start"}
      >
        <IonIcon icon={iconSrc} className={styles.icon1} />
        <Typography className={styles.tg1}>
          Studio D’impression
          <br /> et de Fabrication
        </Typography>
      </Box>
      <Box className={styles.content}>
        <Box className="flex-c-cc">
          <Box className={styles.textContainer}>
            <img
              src={"assets/images/expertise/img6.png"}
              srcSet={"assets/images/expertise/img6.png"}
              alt="logo"
              loading="lazy"
              className={styles.img2}
            />
            <Typography className={styles.tg2}>
              Notre studio d’impression et de fabrication Est équipé des
              dernières technologies d'impression et de façonnage pour offrir à
              nos clients des supports de communication de haute qualité, tels
              que impression papeterie, impression grand format, fabrication TG
              “Tête de gondole” et présentoir, PlV, habillage espace, banner,
              gadgets, stand , jeux personnalisés, habillement, packaging,
              éléments de décoration.
            </Typography>
          </Box>
          <a
            className={styles.link}
            href={pdfUrl}
            target="_blank"
            download={pdfName}
          >
            <Button
              className={styles.btn}
              variant="contained"
              color="primary"
              endIcon={
                <IonIcon
                  icon={"assets/icons/download.svg"}
                  className={styles.endIcon}
                />
              }
            >
              CATALOGUE
            </Button>
          </a>
        </Box>
        <img
          src={"assets/images/expertise/img12.png"}
          srcSet={"assets/images/expertise/img12.png"}
          alt="logo"
          loading="lazy"
          className={styles.img1}
        />
      </Box>
    </Box>
  );
};

export default Section5;
