import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section1.module.scss";

const Section1 = () => {
  return (
    <Box className={styles.section1}>
      <Box className={styles.tgContainer}>
        <Typography className={styles.tg1}>Phygital</Typography>
        <Typography className={styles.tg1}>agency</Typography>
        <Typography className={styles.tg2}>for all </Typography>
        <Typography className={styles.tg2}> generations.</Typography>
      </Box>
      <Box style={{ alignSelf: "center" }}>
        <img
          src={"assets/images/about/img2.png"}
          srcSet={"assets/images/about/img2.png"}
          alt="logo"
          loading="lazy"
          className={styles.img2}
        />
      </Box>
      <Box className={styles.imgContainer}>
        <img
          src={"assets/images/about/img1.png"}
          srcSet={"assets/images/about/img1.png"}
          alt="logo"
          loading="lazy"
          className={styles.img1}
        />
      </Box>
    </Box>
  );
};

export default Section1;
