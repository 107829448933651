import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Slide.module.scss";

const Slide = (props: any) => {
  const { id, image, theme, title, subTitle, details, backHandler } = props;

  return (
    <Box
      className={
        theme === "dark" ? styles.darkContainer : styles.lightContainer
      }
    >
      <Box className="flex-start">
        <Box className={styles.iconContainer}>
          <IonIcon
            icon={"assets/icons/icon6.svg"}
            className={styles.icon}
          />
          <IonIcon
            icon={"assets/icons/back.svg"}
            className={styles.backIcon}
            onClick={backHandler}
          />
        </Box>
        <Typography
          className={theme === "dark" ? styles.dtg2 : styles.ltg2}
          onClick={backHandler}
        >
          {title}
        </Typography>
      </Box>
      <Box className={styles.slideContainer}>
        <Box
          className={
            id === "marketingDigital" || id === "transDigitale"
              ? styles.imgContainer
              : styles.imgContainer1
          }
        >
          <img
            src={image}
            srcSet={image}
            alt="logo"
            loading="lazy"
            className={
              id === "marketingDigital" || id === "transDigitale"
                ? styles.img1
                : styles.img11
            }
          />
          <img
            src={"assets/images/expertise/studiodigital/img2.png"}
            srcSet={"assets/images/expertise/studiodigital/img2.png"}
            alt="logo"
            loading="lazy"
            className={
              id === "marketingDigital" || id === "transDigitale"
                ? styles.img2
                : styles.img21
            }
          />
          <img
            src={"assets/images/expertise/studiodigital/img3.png"}
            srcSet={"assets/images/expertise/studiodigital/img3.png"}
            alt="logo"
            loading="lazy"
            className={
              id === "marketingDigital" || id === "transDigitale"
                ? styles.img3
                : styles.img31
            }
          />
          <img
            src={"assets/images/expertise/studiodigital/img4.png"}
            srcSet={"assets/images/expertise/studiodigital/img4.png"}
            alt="logo"
            loading="lazy"
            className={
              id === "marketingDigital" || id === "transDigitale"
                ? styles.img4
                : styles.img41
            }
          />
          <img
            src={"assets/images/expertise/studiodigital/img5.png"}
            srcSet={"assets/images/expertise/studiodigital/img5.png"}
            alt="logo"
            loading="lazy"
            className={
              id === "marketingDigital" || id === "transDigitale"
                ? styles.img5
                : styles.img51
            }
          />
          <img
            src={"assets/images/expertise/studiodigital/img6.png"}
            srcSet={"assets/images/expertise/studiodigital/img6.png"}
            alt="logo"
            loading="lazy"
            className={
              id === "marketingDigital" || id === "transDigitale"
                ? styles.img6
                : styles.img61
            }
          />
        </Box>
        <Box className={styles.textContainer}>
          <Box className={styles.subTitleContainer}>
            <IonIcon
              icon={"assets/icons/blueCircle.svg"}
              className={styles.icon3}
            />
            <Typography
              className={theme === "dark" ? styles.dtg3 : styles.ltg3}
            >
              {subTitle}
            </Typography>
          </Box>
          <Box className={styles.details}>
            <ul>
              {details.map((item: string) => (
                <li className={theme === "dark" ? styles.dtg4 : styles.ltg4}>
                  <div dangerouslySetInnerHTML={{ __html: item}} />
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </Box>
      <Box className={styles.backIconContainer} onClick={backHandler}>
        <IonIcon
          icon={"assets/icons/arrowLeft.svg"}
          className={styles.backIcon}
          onClick={backHandler}
        />
      </Box>
    </Box>
  );
};

export default Slide;
