export const partners1 = [
  "assets/images/partners/p1.png",
  "assets/images/partners/p2.png",
  "assets/images/partners/p3.png",
  "assets/images/partners/p4.png",
  "assets/images/partners/p5.png",
  "assets/images/partners/p6.png",
  "assets/images/partners/p7.png",
  "assets/images/partners/p8.png",
  "assets/images/partners/p9.png",
  "assets/images/partners/p10.png",
  "assets/images/partners/p11.png",
  "assets/images/partners/p12.png",
  "assets/images/partners/p13.png",
  "assets/images/partners/p14.png",
  "assets/images/partners/p15.png",
];
export const partners2 = [
  "assets/images/partners/p16.png",
  "assets/images/partners/p17.png",
  "assets/images/partners/p18.png",
  "assets/images/partners/p19.png",
  "assets/images/partners/p20.png",
  "assets/images/partners/p21.png",
  "assets/images/partners/p22.png",
  "assets/images/partners/p23.png",
  "assets/images/partners/p24.png",
  "assets/images/partners/p25.png",
  "assets/images/partners/p26.png",
  "assets/images/partners/p27.png",
  "assets/images/partners/p28.png",
];
