import Layout from "../components/layout";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardActions,
  Box,
  Chip,
} from "@mui/material";
import ImageUpload from "../components/upload/ImageUpload";
import { useNavigate, useParams } from "react-router-dom";
import { getArticleById, updateArticle } from "../../../services/admin/article";
import config from "../../../config/config";
import "./style.scss";

function ViewAndEditArticle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState([]);
  const [errors, setErrors] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [imageNames, setImageNames] = useState([]);

  useEffect(() => {
    getArticleById(id)
      .then((data) => {
        setArticle(data);
        setTitle(data.title);
        setDescription(data.description);
        setCategory(data.category);
        setImageNames([data.image]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, showEdit]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const backHandler = () => {
    navigate("/admin/articles");
  };

  const onEdit = () => {
    setShowEdit(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!title.trim()) {
      validationErrors.title = "Le titre est obligatoire";
    }
    if (!description.trim()) {
      validationErrors.description = "La description est obligatoire";
    }
    if (!category.trim()) {
      validationErrors.category = "La catégorie est obligatoire";
    }
    if (image.length === 0 && !imageNames.length) {
      validationErrors.image = "L'image est obligatoire";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("image", image[0]);
    formData.append("imageName", article.image);
    updateArticle(formData, article.id);
    setTitle("");
    setDescription("");
    setCategory("");
    setImage([]);
    setImageNames([]);
    setErrors({});
    setShowEdit(false);
  };
  return (
    <Layout>
      {article && (
        <>
          <Box className="btnContainer2">
            <Button
              variant="contained"
              size="small"
              color="inherit"
              onClick={backHandler}
            >
              Retour
            </Button>
            {!showEdit && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onEdit}
              >
                Modifier
              </Button>
            )}
          </Box>
          {!showEdit && (
            <div className="article">
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                className="article-text"
              >
                <b>{article.title}</b>
              </Typography>
              <Box className={"article-categoryContainer"}>
                <Chip label={article.category} className={"article-category"} />
              </Box>
              <img
                src={config.apiUrl + "/articles/readFile/" + article.image}
                alt={article.title}
                className="article-image"
              />
              <Typography
                gutterBottom
                variant="p"
                component="div"
                mt="20px"
                className="article-text"
              >
                {article.description}
              </Typography>
            </div>
          )}
          {showEdit && (
            <Card style={{ padding: "30px" }}>
              <Typography variant="h5" mb="30px" gutterBottom>
                Modifier un article
              </Typography>
              <ImageUpload
                selectedFiles={image}
                setSelectedFiles={setImage}
                error={errors.image}
                imageNames={imageNames}
                setImageNames={setImageNames}
              />
              {errors.image && (
                <Typography className="errorText">{errors.image}</Typography>
              )}
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Titre"
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={handleTitleChange}
                  margin="normal"
                  error={!!errors.title}
                  helperText={errors.title}
                />
                <TextField
                  label="Catégorie"
                  variant="outlined"
                  fullWidth
                  value={category}
                  onChange={handleCategoryChange}
                  margin="normal"
                  error={!!errors.category}
                  helperText={errors.category}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={description}
                  onChange={handleDescriptionChange}
                  margin="normal"
                  error={!!errors.description}
                  helperText={errors.description}
                />
                <CardActions className="btnContainer">
                  <Button
                    type="submit"
                    variant=""
                    color="primary"
                    onClick={() => setShowEdit(false)}
                  >
                    Annuler
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Modifier
                  </Button>
                </CardActions>
              </form>
            </Card>
          )}
        </>
      )}
    </Layout>
  );
}

export default ViewAndEditArticle;
