import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { menu } from "../../../../utils/constants/adminMenu";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

export default function Sidebar(props) {
  const location = useLocation();
  const { window, mobileOpen, handleDrawerToggle } = props;
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  const drawer = (
    <div>
      <Toolbar style={{ backgroundColor: "black" }}>
        <img
          src={"/assets/images/logo.png"}
          srcSet={"/assets/images/logo.png"}
          alt="logo"
          loading="lazy"
          style={{ width: "100px" }}
        />
      </Toolbar>
      <Divider />
      <List>
        {menu.map((item, index) => (
          <NavLink to={item.path} key={index} className="adminMenuItem">
            <ListItem disablePadding>
              <ListItemButton
                style={
                  activeRoute(item.path)
                    ? { backgroundColor: "rgba(0, 0, 0, 0.04)" }
                    : {}
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      style={{
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      }}
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
