import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Carousel from "../../../components/carousel/Carousel";
import { partners1, partners2 } from "../../../utils/constants/partners";
import styles from "./Partners.module.scss";

const Partners = () => {
  return (
    <Box className={styles.partners}>
      <Box className="flex-c-center">
        <Typography variant="h2" component="h2" className={styles.tg1}>
          ILS NOUS FONT
        </Typography>
        <Typography variant="h2" component="h2" className={styles.tg2}>
          CONFIANCE
        </Typography>
      </Box>
      <Box id="partners" className={styles.partnersContainer}>
        <Carousel images={partners1} />
        <Carousel images={partners2} rtl={true} />
      </Box>
    </Box>
  );
};

export default Partners;
