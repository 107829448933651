import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./Card.module.scss";
import { Box, Chip } from "@mui/material";
const MediaCard = (props: any) => {
  const { category, title, image, onClick } = props;
  return (
    <Card className={styles.card}>
      <CardMedia sx={{ height: 180 }} image={image} title="article image" />
      <CardContent className={styles.cardContent}>
        <Box className={styles.categoryContainer}>
          <Chip label={category} className={styles.category} />
        </Box>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className={styles.title}
        >
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" className={styles.link} onClick={onClick}>
          Lire la suite
        </Button>
      </CardActions>
    </Card>
  );
};

export default MediaCard;
