import { IonIcon } from "@ionic/react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section4.module.scss";
import { useState } from "react";
import {
  developpementWeb,
  marketingDigital,
  transformationDigitale,
} from "../../../../utils/constants/studioDigital";
import FullPageCarousel from "../../../../components/fullPageCarousel/FullPageCarousel";
import Slide from "./Slide";

const Section4 = () => {
  const [iconSrc, setIconSrc] = useState("assets/icons/circle.svg");
  const [slides, setSlides] = useState<any[]>([]);
  const handleClick = () => {
    const element = document.getElementById("s1");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };
  const backHandler = () => {
    console.log("zzzz");
    setSlides([]);
  };
  const showSliderHandler = (expertise: any) => {
    let x: any[] = [];
    expertise.types.map((item: any) =>
      x.push(
        <Slide
          id={expertise.id}
          title={expertise.title}
          subTitle={item.title}
          details={item.details}
          theme={item.theme}
          image={item.image}
          backHandler={backHandler}
        />
      )
    );
    setSlides(x);
  };
  return (
    <>
      {!slides.length && (
        <Box className={styles.container} id="studioDigital">
          <Box
            className={styles.title + " flex-start"}
            onMouseOver={() => setIconSrc("assets/icons/icon3.svg")}
            onMouseOut={() => setIconSrc("assets/icons/circle.svg")}
            onClick={handleClick}
          >
            <IonIcon icon={iconSrc} className={styles.icon1} />
            <Typography className={styles.tg1}>Studio Digital</Typography>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.imgContainer}>
              <img
                src={"assets/images/expertise/studiodigital/img1.png"}
                srcSet={"assets/images/expertise/studiodigital/img1.png"}
                alt="logo"
                loading="lazy"
                className={styles.img1}
              />
              <img
                src={"assets/images/expertise/studiodigital/img2.png"}
                srcSet={"assets/images/expertise/studiodigital/img2.png"}
                alt="logo"
                loading="lazy"
                className={styles.img2}
              />
              <img
                src={"assets/images/expertise/studiodigital/img3.png"}
                srcSet={"assets/images/expertise/studiodigital/img3.png"}
                alt="logo"
                loading="lazy"
                className={styles.img3}
              />
              <img
                src={"assets/images/expertise/studiodigital/img4.png"}
                srcSet={"assets/images/expertise/studiodigital/img4.png"}
                alt="logo"
                loading="lazy"
                className={styles.img4}
              />
              <img
                src={"assets/images/expertise/studiodigital/img5.png"}
                srcSet={"assets/images/expertise/studiodigital/img5.png"}
                alt="logo"
                loading="lazy"
                className={styles.img5}
              />
              <img
                src={"assets/images/expertise/studiodigital/img6.png"}
                srcSet={"assets/images/expertise/studiodigital/img6.png"}
                alt="logo"
                loading="lazy"
                className={styles.img6}
              />
            </Box>
            <ul className={styles.list}>
              <li onClick={() => showSliderHandler(marketingDigital)}>
                <span>Le Marketing Digital</span>
              </li>
              <li onClick={() => showSliderHandler(developpementWeb)}>
                <span>Développement Web</span>
              </li>
              <li onClick={() => showSliderHandler(transformationDigitale)}>
                <span>Transformation Digitale</span>
              </li>
            </ul>
          </Box>
        </Box>
      )}
      {slides.length > 0 ? (
        <div id="fullpagecarousel">
          <FullPageCarousel slides={slides} />
        </div>
      ) : null}
    </>
  );
};

export default Section4;
