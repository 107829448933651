import { Box, Typography } from "@mui/material";
import styles from "./Section2.module.scss";

const Section2 = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.titleContainer}>
        <img
          src={"assets/images/about/img3.png"}
          srcSet={"assets/images/about/img3.png"}
          alt="logo"
          loading="lazy"
          className={styles.img1}
        />
        <Typography className={styles.tg1}>Qui Sommes-Nous?</Typography>{" "}
        <img
          src={"assets/images/about/img4.png"}
          srcSet={"assets/images/about/img4.png"}
          alt="logo"
          loading="lazy"
          className={styles.img2}
        />
      </Box>
      <Box className={styles.textContainer}>
        <Box className={styles.imgContainer2}>
          <img
            src={"assets/images/about/img7.png"}
            srcSet={"assets/images/about/img7.png"}
            alt="logo"
            loading="lazy"
            className={styles.img6}
          />
          <img
            src={"assets/images/about/img5.png"}
            srcSet={"assets/images/about/img5.png"}
            alt="logo"
            loading="lazy"
            className={styles.img4}
          />
        </Box>
        <Typography className={styles.tg2}>
          Nous sommes une agence de communication phygitale fondée en 2013. Nous
          offrons des services de consultation en communication digitale et
          physique pour optimiser l'impact de vos messages en ligne et hors
          ligne. <br /> Nous offrons à nos clients une gamme complète de
          services pour répondre à leurs besoins de communication. <br />
          Notre studio digital vous propose des services de design, de marketing
          digital , de développement web et d'accompagnement en digitalisation
          pour créer des solutions innovantes et efficaces. Notre atelier de
          fabrication s’occupe de la partie physique pour assurer la production
          des éléments de visibilité et les services de marketing direct à nos
          clients.
        </Typography>{" "}
        <Box className={styles.imgContainer1}>
          <img
            src={"assets/images/about/img6.png"}
            srcSet={"assets/images/about/img6.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
          <img
            src={"assets/images/about/img8.png"}
            srcSet={"assets/images/about/img8.png"}
            alt="logo"
            loading="lazy"
            className={styles.img5}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Section2;
