import { Box, Typography, Card } from "@mui/material";
import styles from "./ArticleDetails.module.scss";
import config from "../../../config/config";

const ArticleDetails = (props) => {
  const { currentArticle } = props;
  return (
    <Box className={styles.mainContainer}>
      <Typography variant="h5" component="h2" className={styles.title}>
        {currentArticle.title}
      </Typography>
      <Box className={styles.textContainer}>
        <Box className={styles.imgContainer2}>
          <img
            src={"assets/images/about/img7.png"}
            srcSet={"assets/images/about/img7.png"}
            alt="logo"
            loading="lazy"
            className={styles.img6}
          />
          <img
            src={"assets/images/about/img5.png"}
            srcSet={"assets/images/about/img5.png"}
            alt="logo"
            loading="lazy"
            className={styles.img4}
          />
        </Box>
        <img
          src={config.apiUrl + "/articles/readFiLe/" + currentArticle.image}
          srcSet={config.apiUrl + "/articles/readFiLe/" + currentArticle.image}
          alt="logo"
          loading="lazy"
          className={styles.img1}
        />
        <Box className={styles.imgContainer1}>
          <img
            src={"assets/images/about/img6.png"}
            srcSet={"assets/images/about/img6.png"}
            alt="logo"
            loading="lazy"
            className={styles.img3}
          />
          <img
            src={"assets/images/about/img8.png"}
            srcSet={"assets/images/about/img8.png"}
            alt="logo"
            loading="lazy"
            className={styles.img5}
          />
        </Box>
      </Box>
      <Card className={styles.card}>
        <Typography className={styles.description}>
          {currentArticle.description}
        </Typography>
      </Card>
    </Box>
  );
};

export default ArticleDetails;
