import { IonIcon } from "@ionic/react";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./FullPageCarousel.module.scss";

const FullPageCarousel = (props: any) => {
  const { slides } = props;
  const [prevArrowSrc, setPrevArrowSrc] = useState(
    "assets/icons/bluePrevArrow.svg"
  );

  const [nextArrowSrc, setNextArrowSrc] = useState(
    "assets/icons/blueNextArrow.svg"
  );

  const renderCustomPrevArrow = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => {
    return (
      <div>
        <IonIcon
          onMouseOver={() =>
            setPrevArrowSrc("assets/icons/yellowPrevArrow.svg")
          }
          onMouseOut={() => setPrevArrowSrc("assets/icons/bluePrevArrow.svg")}
          icon={prevArrowSrc}
          className={styles.prevArrow}
          onClick={onClickHandler}
        />
      </div>
    );
  };

  const renderCustomNextArrow = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) => {
    return (
      <div>
        <IonIcon
          onMouseOver={() =>
            setNextArrowSrc("assets/icons/yellowNextArrow.svg")
          }
          onMouseOut={() => setNextArrowSrc("assets/icons/blueNextArrow.svg")}
          icon={nextArrowSrc}
          className={styles.nextArrow}
          onClick={onClickHandler}
        />
      </div>
    );
  };
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={renderCustomPrevArrow}
      renderArrowNext={renderCustomNextArrow}
      infiniteLoop
    >
      {slides.map((slide: any) => (
        <div style={{ height: "100%" }}>{slide}</div>
      ))}
    </Carousel>
  );
};

export default FullPageCarousel;
