import Layout from "../components/layout";
import { useState } from "react";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardActions,
} from "@mui/material";
import ImageUpload from "../components/upload/ImageUpload";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { addProject } from "../../../services/admin/project";
import { Toast } from "../components/toast/Toast";

function AddProject() {
  const navigate = useNavigate();
  const [client, setClient] = useState("");
  const [htag, setHtag] = useState("");
  const [category, setCategory] = useState("");
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [imageNames, setImageNames] = useState([]);

  const handleHtagChange = (e) => {
    setHtag(e.target.value);
  };

  const handleClientChange = (e) => {
    setClient(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!htag.trim()) {
      validationErrors.htag = "Le Htag est obligatoire";
    }
    if (!client.trim()) {
      validationErrors.client = "Le client est obligatoire";
    }
    if (!category.trim()) {
      validationErrors.category = "La catégorie est obligatoire";
    }
    if (!imageNames.length) {
      validationErrors.images =
        "Il faut ajouter au moins une image est obligatoire";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const formData = new FormData();
    formData.append("client", client);
    formData.append("category", category);
    formData.append("htag", htag);
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }
    addProject(formData);
    handleOpenToast("Le projet a été ajouté avec succès!", "success");
    setHtag("");
    setClient("");
    setCategory("");
    setImages([]);
    setImageNames([]);
    setErrors({});
  };

  const backHandler = () => {
    navigate("/admin/projects");
  };
  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      <Card style={{ padding: "30px" }}>
        <Typography variant="h5" mb="30px" gutterBottom>
          Ajouter un projet
        </Typography>
        <ImageUpload
          selectedFiles={images}
          setSelectedFiles={setImages}
          error={errors.images}
          multiple={true}
          setImageNames={setImageNames}
          imageNames={imageNames}
        />
        {errors.images && (
          <Typography className="errorText">{errors.images}</Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Client"
            variant="outlined"
            fullWidth
            value={client}
            onChange={handleClientChange}
            margin="normal"
            error={!!errors.client}
            helperText={errors.client}
          />
          <TextField
            label="Htag"
            variant="outlined"
            fullWidth
            value={htag}
            onChange={handleHtagChange}
            margin="normal"
            error={!!errors.htag}
            helperText={errors.htag}
          />
          <TextField
            label="Catégorie"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={category}
            onChange={handleCategoryChange}
            margin="normal"
            error={!!errors.category}
            helperText={errors.category}
          />
          <CardActions className="btnContainer">
            <Button
              type="submit"
              variant=""
              color="primary"
              onClick={backHandler}
            >
              Retour
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Ajouter
            </Button>
          </CardActions>
        </form>
      </Card>
    </Layout>
  );
}

export default AddProject;
