import Layout from "../components/layout";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardActions,
  Box,
  Divider,
  Grid,
  CardMedia,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../config/config";
import "./style.scss";
import {
  addImagesToProject,
  deleteImageProject,
  getProjectById,
  updateProject,
} from "../../../services/admin/project";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Toast } from "../components/toast/Toast";

function ViewAndEditProject() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState();
  const [client, setClient] = useState("");
  const [htag, setHtag] = useState("");
  const [category, setCategory] = useState("");
  const [errors, setErrors] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [imageNames, setImageNames] = useState([]);
  const [editImages, setEditImages] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    getProjectById(id)
      .then((data) => {
        setProject(data);
        setClient(data.client);
        setHtag(data.htag);
        setCategory(data.category);
        setImageNames(data.images);
        setEditImages(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, showEdit, editImages]);

  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClientChange = (e) => {
    setClient(e.target.value);
  };
  const handleHtagChange = (e) => {
    setHtag(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const backHandler = () => {
    navigate("/admin/projects");
  };

  const onEdit = () => {
    setShowEdit(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!client.trim()) {
      validationErrors.client = "Le client est obligatoire";
    }
    if (!htag.trim()) {
      validationErrors.htag = "Le htag est obligatoire";
    }
    if (!category.trim()) {
      validationErrors.category = "La catégorie est obligatoire";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const formData = new FormData();
    formData.append("client", client);
    formData.append("htag", htag);
    formData.append("category", category);
    updateProject(formData, project.id);
    setClient("");
    setHtag("");
    setCategory("");
    setErrors({});
    setShowEdit(false);
    handleOpenToast("Le projet a été modifié avec succès!", "success");
  };
  const addImages = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const formData = new FormData();
      project.images.forEach((item, index) => {
        formData.append(`images`, item);
      });
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      addImagesToProject(formData, project.id);
      setEditImages(true);
    }
  };
  const handleDelete = (image) => {
    if (imageNames.length > 1) {
      const x = [...imageNames].filter((item) => item !== image);
      setImageNames(x);
      const data = {
        images: x,
        imageToDelete: image,
      };
      deleteImageProject(data, project.id);
      setEditImages(true);
    }
  };
  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      {project && (
        <>
          <Box className="btnContainer2">
            <Button
              variant="contained"
              size="small"
              color="inherit"
              onClick={backHandler}
            >
              Retour
            </Button>
          </Box>
          {!showEdit && (
            <Box>
              <Card
                style={{
                  padding: "30px",
                  marginBottom: "20px",
                  textAlign: "start",
                }}
              >
                <Box className="headingTitle">
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginBottom: "unset" }}
                  >
                    <b>Détails du projet</b>
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={onEdit}
                  >
                    Modifier
                  </Button>
                </Box>
                <Divider style={{ marginBottom: "20px" }} />

                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{ marginBottom: "unset" }}
                >
                  <b>Client: </b>
                  {project.client}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"#1976d2"}
                >
                  #{project.htag}
                </Typography>
                <Typography gutterBottom variant="h6">
                  <b>Catégorie: </b>
                </Typography>
                <Typography gutterBottom variant="p" component="div">
                  {project.category}
                </Typography>
              </Card>
              <Card style={{ padding: "30px" }}>
                <Box className="headingTitle">
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginBottom: "unset" }}
                  >
                    <b>Liste des images</b>
                  </Typography>
                  <input
                    accept="image/*"
                    type="file"
                    style={{ display: "none" }}
                    id="image-upload"
                    onChange={addImages}
                    multiple={true}
                  />
                  <label htmlFor="image-upload">
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                    >
                      Ajouter des images
                    </Button>
                  </label>
                </Box>
                <Divider style={{ marginBottom: "20px" }} />
                <Grid container spacing={2}>
                  {project.images.map((image) => (
                    <Grid item key={image} xs={12} sm={6} md={4} lg={3}>
                      <Card>
                        <div style={{ position: "relative" }}>
                          <CardMedia
                            component="img"
                            alt={image}
                            height="200"
                            image={config.apiUrl + "/projects/readFile/" + image}
                          />
                          {project.images.length > 1 && (
                            <CardActions style={{ padding: "unset" }}>
                              <IconButton
                                className="deleteBtn"
                                aria-label="Delete"
                                onClick={() => handleDelete(image)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </CardActions>
                          )}
                        </div>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Box>
          )}
          {showEdit && (
            <Card style={{ padding: "30px" }}>
              <Typography variant="h5" mb="30px" gutterBottom>
                Modifier les détails du projet
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Client"
                  variant="outlined"
                  fullWidth
                  value={client}
                  onChange={handleClientChange}
                  margin="normal"
                  error={!!errors.client}
                  helperText={errors.client}
                />
                <TextField
                  label="Htag"
                  variant="outlined"
                  fullWidth
                  value={htag}
                  onChange={handleHtagChange}
                  margin="normal"
                  error={!!errors.htag}
                  helperText={errors.htag}
                />
                <TextField
                  label="Catégorie"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={category}
                  onChange={handleCategoryChange}
                  margin="normal"
                  error={!!errors.category}
                  helperText={errors.category}
                />
                <CardActions className="btnContainer">
                  <Button
                    type="submit"
                    variant=""
                    color="primary"
                    onClick={() => setShowEdit(false)}
                  >
                    Annuler
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Modifier
                  </Button>
                </CardActions>
              </form>
            </Card>
          )}
        </>
      )}
    </Layout>
  );
}

export default ViewAndEditProject;
