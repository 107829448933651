export const routes = [
  { name: "Accueil", path: "/" },
  { name: "A propos", path: "/about" },
  {
    name: "Expertise",
    path: "/expertise",
    subMenu: [
      {
        name: "Studio Graphique",
        path: "/expertise",
        sectionId: "studioGraphique",
        subMenu: [
          { name: "Branding", path: "/expertise", sectionId: "branding" },
          { name: "Web Design", path: "/expertise", sectionId: "webdesign" },
          { name: "Vidéo 2D/3D", path: "/expertise", sectionId: "video2D3D" },
          {
            name: "Production Audiovisuelle",
            path: "/expertise",
            sectionId: "productionaudiovisuelle",
          },
        ],
      },
      {
        name: "Studio Digital",
        path: "/expertise",
        sectionId: "studioDigital",
        subMenu: [
          { name: "Marketing Digital", path: "/expertise", sectionId: "" },
          { name: "Développement Web", path: "/expertise", sectionId: "" },
          {
            name: "Transformation Digitale",
            path: "/expertise",
            sectionId: "",
          },
        ],
      },
      {
        name: "Studio D’impression",
        path: "/expertise",
        sectionId: "studioImpression",
      },
      {
        name: "Communication 360°",
        path: "/expertise",
        sectionId: "communication360",
      },
    ],
  },
  { name: "Portfolio", path: "/portfolio" },
  { name: "Blog", path: "/blog" },
  { name: "Contact", path: "/contact" },
];
