import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import ImageCarousel from "../../../components/imageCarousel/ImageCarousel";
import styles from "./ClientDetails.module.scss";
import { IonIcon } from "@ionic/react";

const ClientDetails = (props: any) => {
  const { client, backHandler } = props;
  const myElementRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (myElementRef.current && !myElementRef.current.contains(event.target)) {
      console.log("Clicked outside the element!");
      backHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
  }, []);
  return (
    <Box ref={myElementRef} className={styles.clientDetailsContainer}>
      <Box id="imagecarousel">
        <ImageCarousel images={client.images} />
      </Box>
      <Box className={styles.tgContainer}>
        <Typography className={styles.tg1}>
          <span className={styles.tg5}>Client:</span>{" "}
          <div dangerouslySetInnerHTML={{ __html: client.client }} />
        </Typography>
        <Typography className={styles.tg2}>#{client.htag}</Typography>
        <Typography className={styles.tg4}>Category:</Typography>
        <Typography className={styles.tg3}>{client.category}</Typography>
      </Box>
      <IonIcon
        icon={"assets/icons/back.svg"}
        className={styles.backIconMobile}
        onClick={backHandler}
      />
    </Box>
  );
};

export default ClientDetails;
