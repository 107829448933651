import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "./Section3.module.scss";

const Section3 = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.mindset}>
        <Typography className={styles.tg1}>Notre Mindset</Typography>
        <Typography className={styles.tg2}>
          Nous ne voyons pas les choses comme tout le monde. Dans notre équipe,
          chacun vient d’un univers qui lui appartient. Que ce soit le design,
          le marketing, la publicité ou l’événementiel, ce sont tous ces
          savoir-faire qui nourrissent l’avant-gardisme et l’orginatilé qui nous
          caractérisent. Nous délivrons une communication efficace , cellle qui
          est mieux exprimée par nos deux principes directeurs
        </Typography>
        <Typography className={styles.tg3}>les couleurs et les émotions.</Typography>
      </Box>
      <Box className={styles.objectifs}>
        <Typography className={styles.tg1}>Nos Objectifs</Typography>
        <Typography className={styles.tg2}>
          Apporter une touche de créativité nouvelle, unique et sans précédent{" "}
          <br /> Développer une forte présence sur les médias sociaux
          <br />
          Générer de meilleurs résultats commerciaux grâce à des vidéos de haute
          qualité et à la création de contenu
          <br /> Rendre votre marque plus pertinente pour son public cible,
          maintenant et dans le futur
        </Typography>
      </Box>
    </Box>
  );
};

export default Section3;
