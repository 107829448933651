import Layout from "../components/layout";
import { useState } from "react";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardActions,
} from "@mui/material";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { sendNewsletter } from "../../../services/sendEmail";
import { getAll } from "../../../services/subscriber";
import { Toast } from "../components/toast/Toast";

function SendNewsletter() {
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    // Check if title is empty
    if (!subject.trim()) {
      validationErrors.subject = "Le sujet est obligatoire";
    }
    // Check if description is empty
    if (!message.trim()) {
      validationErrors.message = "La message est obligatoire";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    getAll()
      .then((data) => {
        const mailList = data.map((item) => item.email);
        const mailOptions = {
          to: [...mailList],
          subject: subject,
          message: message,
        };
        sendNewsletter(mailOptions);
        handleOpenToast("Newsletter a été envoyé avec succès!", "success");
        setSubject("");
        setMessage("");
        setErrors({});
      })
      .catch((error) => {
        handleOpenToast("Il y a un problème!", "error");
        console.error("Error fetching data:", error);
      });
  };

  const backHandler = () => {
    navigate("/admin/newsletter");
  };
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      <Card style={{ padding: "30px" }}>
        <Typography variant="h5" mb="30px" gutterBottom>
          Envoyer un newsletter
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            value={subject}
            onChange={handleSubjectChange}
            margin="normal"
            error={!!errors.subject}
            helperText={errors.subject}
          />
          <TextField
            label="Message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={handleMessageChange}
            margin="normal"
            error={!!errors.message}
            helperText={errors.message}
          />
          <CardActions className="btnContainer">
            <Button
              type="submit"
              variant=""
              color="primary"
              onClick={backHandler}
            >
              Retour
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Envoyer
            </Button>
          </CardActions>
        </form>
      </Card>
    </Layout>
  );
}

export default SendNewsletter;
