import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Layout from "../components/layout";
import { NavLink, useNavigate } from "react-router-dom";
import CustomCard from "../components/card/CustomCard";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Card, Button, Box } from "@mui/material";
import { deleteArticle, getAll } from "../../../services/admin/article";
import { Pagination, PaginationItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Toast } from "../components/toast/Toast";
import "./style.scss";

const Articles = () => {
  const navigate = useNavigate();
  const perPage = 6;
  const [articles, setArticles] = useState();
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    setLoading(true);
    getAll(page, perPage)
      .then((data) => {
        setLoading(false);
        setArticles(data.rows);
        setNumberOfPages(Math.ceil(data.count / perPage));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, perPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenToast = (message, severity) => {
    setMsg(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleDelete = (id) => {
    deleteArticle(id)
      .then(() => {
        handleOpenToast("L'article a été supprimé avec succès!", "success");
        getAll(page, perPage)
          .then((data) => {
            setArticles(data.rows);
            setNumberOfPages(Math.ceil(data.count / perPage));
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        handleOpenToast("Il y a un problème!", "error");
        console.error("Error deleting article:", error);
      });
  };

  const handleView = (id) => {
    navigate("/admin/articles/" + id);
  };

  return (
    <Layout>
      <Toast
        open={open}
        message={msg}
        severity={severity}
        onClose={handleCloseToast}
      />
      {!loading && articles && articles.length === 0 && (
        <Card style={{ padding: "30px" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
            style={{ marginBottom: "20px" }}
          >
            Aucun article ! Veuillez ajouter votre premier article
          </Typography>
          <NavLink to={"/admin/articles/new"}>
            <Button size="small">Ajouter un article</Button>
          </NavLink>
        </Card>
      )}
      {!loading && articles && articles.length > 0 && (
        <>
          <Typography
            variant="h4"
            noWrap
            sx={{ flexGrow: 1 }}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Liste des articles
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => navigate("/admin/articles/new")}
            >
              <AddIcon /> Ajouter un article
            </Button>
          </Box>

          <Grid container spacing={2} mt="5px">
            {articles.map((item) => {
              const data = item;
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                  <CustomCard
                    data={data}
                    onDelete={handleDelete}
                    onView={handleView}
                    type="article"
                  />
                </Grid>
              );
            })}
          </Grid>
          {numberOfPages > 1 && (
            <Box display="flex" justifyContent="center" mt="40px">
              <Pagination
                count={numberOfPages}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem component="button" {...item} />
                )}
              />
            </Box>
          )}
        </>
      )}
      {loading && <CircularProgress style={{ marginTop: "30px" }} />}
    </Layout>
  );
};

export default Articles;
