export const checkIsValidEmail = (val) => {
  /** mail regex */
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  return val ? regex.test(val) : false;
};

export const validatePhoneNumber = (input_str) => {
  var re = /^(00216|216|\+216)(9|5|4|2)([0-9]{7})$/;
  return re.test(input_str);
};
