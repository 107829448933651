import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from "./views/home";
import About from "./views/about";
import Expertise from "./views/expertise";
import Contact from "./views/contact";
import Blog from "./views/blog";
import Portfolio from "./views/portfolio";
import "./App.css";
import "../src/fonts/fonts.css";
import Admin from "./views/admin";
import Articles from "./views/admin/articles";
import { useEffect, useState } from "react";
import Projects from "./views/admin/projects";
import Newsletter from "./views/admin/newsletter";
import AddArticle from "./views/admin/articles/AddArticle";
import AddProject from "./views/admin/projects/AddProject";
import SendNewsletter from "./views/admin/newsletter/SendNewsletter";
import ViewAndEditArticle from "./views/admin/articles/ViewAndEditArticle";
import ViewAndEditProject from "./views/admin/projects/ViewAndEditProject";
import Login from "./views/admin/login";
import Videos from "./views/admin/videos";
import AddVideo from "./views/admin/videos/AddVideo";

function App() {
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    const x = localStorage.getItem("isConnected");
    setIsConnected(x === "true" ? true : false);
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/admin" element={<Login />} />
          {isConnected && (
            <>
              <Route path="/admin/articles" element={<Articles />} />
              <Route path="/admin/projects" element={<Projects />} />
              <Route path="/admin/newsletter" element={<Newsletter />} />
              <Route path="/admin/articles/new" element={<AddArticle />} />
              <Route path="/admin/projects/new" element={<AddProject />} />
              <Route
                path="/admin/articles/:id"
                element={<ViewAndEditArticle />}
              />
              <Route
                path="/admin/projects/:id"
                element={<ViewAndEditProject />}
              />

              <Route
                path="/admin/newsletter/send"
                element={<SendNewsletter />}
              />
              <Route path="/admin/videos" element={<Videos />} />
              <Route path="/admin/videos/new" element={<AddVideo />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
