import config from "../../config/config";

export const addProject = (formData) => {
  fetch(config.apiUrl + "/projects", {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("project was added successfully.");
      } else {
        console.log("Failed to add project.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const getAll = async (page, perPage) => {
  const searchParams = new URLSearchParams({ page, perPage });
  const apiUrl =
    page && perPage
      ? `${config.apiUrl + "/projects"}?${searchParams.toString()}`
      : `${config.apiUrl + "/projects"}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};

export const deleteProject = async (id) => {
  const apiUrl = `${config.apiUrl + "/projects/" + id}`;

  const response = await fetch(apiUrl, {
    method: "DELETE",
  });
  const json = await response.json();
  return json;
};

export const getProjectById = async (id) => {
  const apiUrl = `${config.apiUrl + "/projects/" + id}`;

  const response = await fetch(apiUrl, {
    method: "GET",
  });
  const json = await response.json();
  return json;
};

export const updateProject = (formData, id) => {
  fetch(config.apiUrl + "/projects/" + id, {
    method: "PUT",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("Project was updated successfully.");
      } else {
        console.log("Failed to update project.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const deleteImageProject = (data, id) => {
  fetch(config.apiUrl + "/projects/delete-image/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        console.log("Project was updated successfully.");
      } else {
        console.log("Failed to update project.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export const addImagesToProject = (formData, id) => {
  fetch(config.apiUrl + "/projects/add-images/" + id, {
    method: "PUT",
    body: formData,
  })
    .then((response) => {
      if (response.ok) {
        console.log("Project was updated successfully.");
      } else {
        console.log("Failed to update project.");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};
