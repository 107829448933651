import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CardMedia,
} from "@mui/material";
import "./style.scss";
import config from "../../../../config/config";

const CustomCard = ({ data, type, onDelete, onView }) => {
  return (
    <Card id="customCard">
      <CardMedia
        component="img"
        alt={data.title}
        height="200"
        image={
          type === "article"
            ? config.apiUrl + "/articles/readFile/" + data.image
            : config.apiUrl + "/projects/readFile/" + data.images[0]
        }
      />
      <CardContent className="cardContents">
        <Typography variant="h5" component="div">
          {type === "project" ? data.client : data.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" className="text">
          {type === "project" ? data.category : data.description}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "end", paddingBottom: "10px" }}>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => onDelete(data.id)}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onView(data.id)}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

export default CustomCard;
